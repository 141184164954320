import { connect } from 'react-redux';
import { toggleSidenavMobileOpened } from 'ducks/config/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({ config: { headerFixed } }: RootState) => ({
  user: {},
});

const mapDispatchToProps = {
  toggleSidenavMobileOpened,
};

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
