import { RuleObject } from 'antd/es/form';
import { getIntl } from 'components/main/Intl';

const PHONE_REGEX = /^[+\-()\d\s]+$/;

export const validatePhoneNumberRule = () => {
  return () => ({
    validator(_: RuleObject, phoneNumber: string) {
      if (!phoneNumber) {
        return Promise.resolve();
      }

      if (!PHONE_REGEX.test(phoneNumber)) {
        return Promise.reject(
          getIntl().formatMessage({
            defaultMessage: 'Wprowadź poprawny numer telefonu',
          }),
        );
      }

      return Promise.resolve();
    },
  });
};
