import { Skeleton } from 'antd';
import cn from 'classnames';
import { useGetPaymentMethodsQuery } from 'ducks/payment/service';

import styles from './styles.module.scss';

type Props = {
  onChange?: (id: string) => void;
  value?: string;
};

const PaymentMethodsField: React.FC<Props> = ({ onChange, value }) => {
  const { data = [], isLoading } = useGetPaymentMethodsQuery();

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        {Array.from({ length: 8 }, (_, idx) => (
          <Skeleton.Button key={`skl-${idx}`} active={true} size="large" />
        ))}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {data.map(({ name, id, img }) => (
        <button
          className={cn(styles.item, { [styles.itemActive]: id === value })}
          type="button"
          key={id}
          onClick={() => onChange?.(id)}
        >
          <img src={img} alt={name} />
        </button>
      ))}
    </div>
  );
};

export default PaymentMethodsField;
