import { useState } from 'react';

export type ButtonStates = 'clean' | 'filled' | 'submitted' | 'final';

const useButtonState = () => {
  const [buttonState, setButtonState] = useState<ButtonStates>('clean');

  // helpers state

  const shouldRenderPrice =
    buttonState === 'submitted' || buttonState === 'final';

  return {
    buttonState,
    setButtonState,
    shouldRenderPrice,
  };
};

export default useButtonState;
