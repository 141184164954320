import { Form, Input, Modal, notification } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/ui/atoms/Loader/Loader';
import { useSendDiscountCodeMutation } from 'ducks/payment/service';

const { useForm, Item } = Form;

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DiscountCodeModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [notify, contextHolder] = notification.useNotification();

  const [form] = useForm();
  const [sendCode, { isLoading, isSuccess, isError }] =
    useSendDiscountCodeMutation();
  const handleFormSubmit = () => {
    if (isLoading) return;

    form
      .validateFields()
      .then(sendCode)
      .catch(() => {
        // do nothing
      });
  };

  useEffect(() => {
    if (isLoading) return;

    if (isSuccess) {
      notify.success({
        message: (
          <FormattedMessage defaultMessage="Poprawnie dodano kod rabatowy!" />
        ),
      });
      onClose();
    }

    if (isError)
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wprowadzony kod rabatowy nie istnieje lub został już przez Ciebie wykorzystany." />
        ),
      });
  }, [isLoading, isSuccess, isError, notify, onClose]);

  return (
    <>
      {contextHolder}
      <Modal
        title={<FormattedMessage defaultMessage="Zrealizuj kod rabatowy" />}
        open={isOpen}
        onCancel={onClose}
        onOk={handleFormSubmit}
        okText={<FormattedMessage defaultMessage="Dodaj kod" />}
        cancelText={<FormattedMessage defaultMessage="Anuluj" />}
      >
        <Loader visible={isLoading}>
          <Form form={form}>
            <Item
              name="code"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage defaultMessage="Podaj kod rabatowy" />
                  ),
                },
              ]}
            >
              <Input type="text" />
            </Item>
          </Form>
        </Loader>
      </Modal>
    </>
  );
};

export default DiscountCodeModal;
