/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfigState } from './types';

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    headerFixed: true,
    sidenavMobileOpened: false,
    sidenavColor: 'transparent',
    layoutRtl: false,
  } as ConfigState,
  reducers: {
    setHeaderFixed: (state, { payload }: PayloadAction<boolean>) => {
      state.headerFixed = payload;
    },
    setSidenavMobileOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.sidenavMobileOpened = payload;
    },
    toggleSidenavMobileOpened: (state) => {
      state.sidenavMobileOpened = !state.sidenavMobileOpened;
    },
    setSidenavColor: (state, { payload }: PayloadAction<string>) => {
      state.sidenavColor = payload;
    },
    setLayoutRtl: (state, { payload }: PayloadAction<boolean>) => {
      state.layoutRtl = payload;
    },
  },
});

// Export the reducer and all synchronous actions
export const config = configSlice.reducer;
export const {
  setHeaderFixed,
  setSidenavMobileOpened,
  toggleSidenavMobileOpened,
  setSidenavColor,
  setLayoutRtl,
} = configSlice.actions;
