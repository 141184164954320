import { BrowserRouter } from 'react-router-dom';

import Main from 'components/layout/Main';
import Routing from './Routing';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Main>
        <Routing />
      </Main>
    </BrowserRouter>
  );
};

export default App;
