import type { Document } from 'ducks/document/types';
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Select, Tooltip } from 'antd';
import { Rule } from 'antd/es/form';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import TagInput from 'components/ui/molecules/TagInput/TagInput';
import { languagesOptions } from 'utilities/consts';

export type FormListItemRecord<TData> = {
  key: number;
  title: ReactElement | string;
  cellItems: (data: TData) => {
    name?: keyof TData & string;
    rules?: Rule[];
    render?: ReactElement;
  }[];
};

export type ListItem = Partial<Omit<Document, 'id'>> & {
  id: string;
};

export type HandleAddProps = {
  langFrom: string;
  langTo: string;
  isCertified: boolean;
}[];

type FormListItem = FormListItemRecord<ListItem>;

type Args = {
  handleDeleteRow: (id: string) => void;
  templatesOptions: {
    value: string;
    label: string;
  }[];
  templatesLoading: boolean;
};

export const sharedFormListItems = ({
  handleDeleteRow,
  templatesOptions,
  templatesLoading,
}: Args): FormListItem[] => [
  {
    key: 7,
    title: <FormattedMessage defaultMessage="Nazwa dokumentu" />,
    cellItems: () => [
      {
        name: 'name',
        render: <Input />,
        rules: [
          {
            required: true,
            message: <FormattedMessage defaultMessage="Uzupełnij nazwę" />,
          },
        ],
      },
    ],
  },
  {
    key: 1,
    title: <FormattedMessage defaultMessage="Liczba znaków" />,
    cellItems: () => [
      {
        name: 'characters',
        render: <InputNumber min={1} defaultValue={1500} />,
        rules: [
          {
            required: true,
            message: (
              <FormattedMessage defaultMessage="Uzupełnij liczbę znaków" />
            ),
          },
        ],
      },
    ],
  },
  {
    key: 2,
    title: (
      <span>
        <FormattedMessage defaultMessage="Frazy kluczowe" />{' '}
        <Tooltip
          title={
            <FormattedMessage defaultMessage="Wciśnięcie entera powoduje dodanie frazy kluczowej" />
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </span>
    ),
    cellItems: () => [
      {
        name: 'phrases',
        render: <TagInput />,
      },
    ],
  },
  {
    key: 3,
    title: (
      <span>
        <FormattedMessage defaultMessage="Nagłówki" />{' '}
        <Tooltip
          title={
            <FormattedMessage defaultMessage="Wciśnięcie entera powoduje dodanie nagłówka" />
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </span>
    ),
    cellItems: () => [
      {
        name: 'headers',
        render: <TagInput />,
      },
    ],
  },
  {
    key: 4,
    title: <FormattedMessage defaultMessage="Szablon tekstu" />,
    cellItems: () => [
      {
        name: 'template_id',
        render: (
          <Select
            placeholder={<FormattedMessage defaultMessage="Wybierz Szablon" />}
            options={templatesOptions}
            loading={templatesLoading}
          />
        ),
        rules: [
          {
            required: true,
            message: <FormattedMessage defaultMessage="Uzupełnij szablon" />,
          },
        ],
      },
    ],
  },
  {
    key: 5,
    title: <FormattedMessage defaultMessage="Język tekstu" />,
    cellItems: () => [
      {
        name: 'lang',
        render: (
          <Select
            placeholder={<FormattedMessage defaultMessage="Wybierz język" />}
            options={languagesOptions}
          />
        ),
        rules: [
          {
            required: true,
            message: <FormattedMessage defaultMessage="Uzupełnij język" />,
          },
        ],
      },
    ],
  },
  // { // for now it is hidden
  //   key: 6,
  //   title: <FormattedMessage defaultMessage="Synonimizacja" />,
  //   cellItems: () => [
  //     {
  //       name: 'isSynonimized',
  //       render: <Checkbox />,
  //       valuePropName: 'checked',
  //     },
  //   ],
  // },
  {
    key: 8,
    title: <span />,
    cellItems: ({ id }) => [
      {
        render: (
          <Button
            type="link"
            danger
            onClick={() => handleDeleteRow(id)}
            icon={<CloseCircleOutlined />}
          >
            <FormattedMessage defaultMessage="Usuń" />
          </Button>
        ),
      },
    ],
  },
];
