import { getIntl } from 'components/main/Intl';
import { OperationType, PaymentDetails } from './types';

export const getOperationLabel = (
  operationType: OperationType,
  operationDetails: PaymentDetails,
): string => {
  switch (operationType) {
    case OperationType.Payment:
      return getIntl().formatMessage(
        {
          defaultMessage: 'wpłata ({paymentHash})',
        },
        operationDetails,
      );
    case OperationType.OrderDocument:
      return getIntl().formatMessage(
        {
          defaultMessage: 'zamówienie treści {documentId}',
        },
        operationDetails,
      );
    default:
      return getIntl().formatMessage(
        {
          defaultMessage: 'bonus ({discountCode})',
        },
        operationDetails,
      );
  }
};
