import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Typography, Modal, notification, Button, Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Table from 'components/ui/molecules/Table';
import {
  useDeleteLicenseMutation,
  useGetLicensesQuery,
} from 'ducks/licenses/service';
import LicenseModal from './components/LicenseModal';
import { columns, LicenseRow } from './config';

const { Title } = Typography;
const { confirm } = Modal;

const License: React.FC = () => {
  const [notify, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<LicenseRow>();

  const intl = useIntl();
  const { data, isFetching } = useGetLicensesQuery();
  const [
    deleteLicense,
    {
      isLoading: isDeleting,
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteLicenseMutation();

  const handleOnClickEdit = (id: number) => {
    setSelectedLicense(data?.find((license) => license.id === id));
    setIsModalOpen(true);
  };

  const handleOnClickDelete = (id: number) => {
    confirm({
      title: intl.formatMessage({
        defaultMessage: 'Czy jesteś pewny że chcesz usunąć wybraną licencję?',
      }),
      icon: <ExclamationCircleFilled />,
      okText: intl.formatMessage({
        defaultMessage: 'Tak',
      }),
      okType: 'danger',
      cancelText: intl.formatMessage({
        defaultMessage: 'Nie',
      }),
      onOk() {
        deleteLicense(id);
      },
    });
  };

  useEffect(() => {
    if (isDeleting) return;
    if (isDeleteError) {
      notify.error({
        message: <FormattedMessage defaultMessage="Spróbuj ponownie" />,
      });
    }

    if (isDeleteSuccess) {
      notify.success({
        message: (
          <FormattedMessage defaultMessage="Licencja została usunięta" />
        ),
      });
    }
  }, [isDeleteError, isDeleteSuccess, isDeleting, notify]);

  return (
    <div className="mb-24">
      {contextHolder}
      <LicenseModal
        notify={notify}
        isOpen={isModalOpen}
        selectedLicense={selectedLicense}
        onClose={() => setIsModalOpen(false)}
      />

      <Table<LicenseRow>
        isLoading={isFetching}
        cardHeader={
          <Row
            justify="space-between"
            align="middle"
            className="ant-form-item-row"
            gutter={[24, 12]}
          >
            <Col>
              <Title level={5}>
                <FormattedMessage defaultMessage="Twoje licencje" />
              </Title>
            </Col>
            <Col>
              <Button
                icon={<PlusCircleOutlined />}
                type="primary"
                onClick={() => setIsModalOpen(true)}
              >
                <FormattedMessage defaultMessage="Dodaj licencję" />
              </Button>
            </Col>
          </Row>
        }
        data={data ?? []}
        columns={columns(handleOnClickEdit, handleOnClickDelete)}
      />
    </div>
  );
};

export default License;
