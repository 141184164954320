import { Popover, Tag, Typography } from 'antd';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { DocumentFlags, DocumentStatus } from 'ducks/document/types';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const { Title } = Typography;

const documentFlagsLabels = {
  [DocumentFlags.HATE]: (
    <FormattedMessage defaultMessage="Treść oznaczona jako promująca nienawiść." />
  ),
  [DocumentFlags.HATE_THREATING]: (
    <FormattedMessage defaultMessage="Treść oznaczona jako zawierająca elementy nawołujące do nienawiści." />
  ),
  [DocumentFlags.HARASSMENT]: (
    <FormattedMessage defaultMessage="Treść oznaczona jako podżegająca do nękania." />
  ),
  [DocumentFlags.HARASSMENT_THREATING]: (
    <FormattedMessage defaultMessage="Treść oznaczona jako zawierająca groźby." />
  ),
  [DocumentFlags.SELF_HARM]: (
    <FormattedMessage defaultMessage="Treść oznaczona jako traktująca o samookaleczaniu." />
  ),
  [DocumentFlags.SELF_HARM_INTENT]: (
    <FormattedMessage defaultMessage="Treść oznaczona jako promowanie samookaleczania." />
  ),
  [DocumentFlags.SELF_HARM_INSTRUCTIONS]: (
    <FormattedMessage defaultMessage="Treść oznaczona jako zachęcająca do samookaleczania." />
  ),
  [DocumentFlags.SEXUAL]: (
    <FormattedMessage defaultMessage="Treść oznaczona jako promowanie seksualności." />
  ),
  [DocumentFlags.SEXUAL_MINORS]: (
    <FormattedMessage defaultMessage="Treść oznaczona jako podejrzana o pedofilię." />
  ),
  [DocumentFlags.VIOLENCE]: (
    <FormattedMessage defaultMessage="Treść oznaczona jako traktująca o przemocy." />
  ),
  [DocumentFlags.VIOLENCE_GRAPHICS]: (
    <FormattedMessage defaultMessage="Treść oznaczona jako promująca przemoc." />
  ),
};

export const getDocumentStatusTag = (
  status: DocumentStatus,
  flags: DocumentFlags[] | undefined,
): ReactElement =>
  ({
    [DocumentStatus.Done]: (
      <Tag color="success">
        <FormattedMessage defaultMessage="Zrealizowane" />
      </Tag>
    ),
    [DocumentStatus.InProgress]: (
      <Tag color="warning">
        <FormattedMessage defaultMessage="W trakcie" />
      </Tag>
    ),
    [DocumentStatus.Flagged]: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tag color="error">
          <FormattedMessage defaultMessage="Anulowane - naruszenie zasad" />
        </Tag>
        <Popover
          content={
            <>
              <Title level={5}>
                <FormattedMessage defaultMessage="Zapytanie niezgodne z regulaminem" />
              </Title>
              {flags?.map((flag) => (
                <p style={{ width: '100%' }}>{documentFlagsLabels[flag]}</p>
              ))}
            </>
          }
        >
          <AiOutlineInfoCircle />
        </Popover>
      </div>
    ),
    [DocumentStatus.Canceled]: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Tag color="error">
          <FormattedMessage defaultMessage="Anulowane" />
        </Tag>
        <Popover
          content={
            <p>
              <FormattedMessage defaultMessage="Brak środków na koncie" />
            </p>
          }
        >
          <AiOutlineInfoCircle />
        </Popover>
      </div>
    ),
  }[status]);

export const getListItem = (
  text: ReactElement,
  value: string | ReactElement,
): ReactElement => (
  <li>
    <span>{text}: </span>
    <span className="text-xs font-weight-bold">{value}</span>
  </li>
);
