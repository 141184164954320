import { AxiosResponse } from 'axios';
import { isObject, isArray, reduce } from 'lodash';

export const formatObjectKeys = (
  obj:
    | Record<string, unknown>
    | ArrayBuffer
    | Array<any>
    | AxiosResponse<any>
    | URLSearchParams,
  formatFn: (param: string) => string,
): Record<string, unknown> | Array<any> | URLSearchParams | ArrayBuffer => {
  if (!isObject(obj) || obj instanceof ArrayBuffer) return obj;
  if (isArray(obj)) return obj.map((v) => formatObjectKeys(v, formatFn));
  if (obj instanceof URLSearchParams) return obj;

  return reduce(
    obj,
    (r, v, k) => {
      return {
        ...r,
        ...(typeof v === 'undefined'
          ? {}
          : {
              [k.includes('-') ? k : formatFn(k)]: formatObjectKeys(
                v,
                formatFn,
              ),
            }),
      };
    },
    {},
  );
};
