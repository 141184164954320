import { InfoCircleOutlined } from '@ant-design/icons';
import { Space, Typography, Tooltip } from 'antd';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Loader from 'components/ui/atoms/Loader';
import { CalculateDocumentResponse } from 'ducks/document/types';
import styles from './styles.module.scss';

type Props = {
  isLoading: boolean;
  data: CalculateDocumentResponse | undefined;
  copywriterPrice: number;
  shouldRender: boolean;
};

const PriceTooltip: React.FC<Props> = ({
  isLoading,
  data,
  copywriterPrice,
  shouldRender,
}) => {
  const price = data?.price ?? 1;
  return shouldRender ? (
    <Loader
      visible={isLoading}
      text={
        <FormattedMessage defaultMessage="Trwa wyliczanie kwoty do zapłaty" />
      }
    >
      {data && (
        <Space className={styles.priceWrapper}>
          <>
            <Typography.Title
              level={3}
              style={{ margin: 0, textAlign: 'center' }}
            >
              <FormattedNumber
                value={price}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="PLN"
              />
            </Typography.Title>

            <Tooltip
              title={`Szacunkowa oszczędność względem tradycyjnego zlecenia copywriterowi wynosi ok. ${copywriterPrice} zł`}
            >
              <span>
                <InfoCircleOutlined />
              </span>
            </Tooltip>
          </>
        </Space>
      )}
    </Loader>
  ) : null;
};

export default PriceTooltip;
