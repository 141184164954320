import { Checkbox, Form, FormInstance, Input } from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserFormType } from 'ducks/user/types';
import { isValidNip } from 'utilities/utilities';
import FormCommon, { WrapperConfig } from '../FormCommon';
import { validatePhoneNumberRule } from './config';

const { Item } = Form;

const getFieldLabel = (fieldName: string): ReactElement | string =>
  ({
    company: <FormattedMessage defaultMessage="Nazwa firmy" />,
    nip: <FormattedMessage defaultMessage="NIP" />,
    postCode: <FormattedMessage defaultMessage="Kod pocztowy" />,
    city: <FormattedMessage defaultMessage="Miasto" />,
    address: <FormattedMessage defaultMessage="Adres" />,
    phone: <FormattedMessage defaultMessage="Numer telefonu" />,
  }[fieldName] || '');

const FormCompany: React.FC = () => {
  const intl = useIntl();
  const [wrapperConfig, setWrapperConfig] = useState<WrapperConfig>();
  const [isCompanyRequired, setIsCompanyRequired] = useState(false);
  const [form, setForm] = useState<FormInstance>();

  useEffect(() => {
    if (!form) return;
    setIsCompanyRequired(form.getFieldValue('isCompany'));
  }, [form]);

  return (
    <FormCommon
      type={UserFormType.Invoice}
      getFieldLabel={getFieldLabel}
      onWrapperConfigChange={setWrapperConfig}
      getForm={(baseForm) => setForm(baseForm)}
    >
      <Item name="isCompany" wrapperCol={wrapperConfig} valuePropName="checked">
        <Checkbox onChange={(e) => setIsCompanyRequired(e.target.checked)}>
          <FormattedMessage defaultMessage="Faktura VAT na firmę" />
        </Checkbox>
      </Item>
      {isCompanyRequired && (
        <Item
          label={getFieldLabel('company')}
          name="company"
          rules={[
            {
              required: isCompanyRequired,
              message: (
                <FormattedMessage defaultMessage="To pole jest wymagane jeżeli chcesz faktury na firmę" />
              ),
            },
          ]}
        >
          <Input />
        </Item>
      )}
      <Item
        label={getFieldLabel('address')}
        name="address"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage defaultMessage="To pole jest wymagane" />
            ),
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label={getFieldLabel('postCode')}
        name="postCode"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage defaultMessage="To pole jest wymagane" />
            ),
          },
          {
            pattern: /^[0-9]{2}-[0-9]{3}$/,
            message: (
              <FormattedMessage defaultMessage="Wprowadź poprawny kod pocztowy" />
            ),
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label={getFieldLabel('city')}
        name="city"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage defaultMessage="To pole jest wymagane" />
            ),
          },
        ]}
      >
        <Input />
      </Item>
      {isCompanyRequired && (
        <Item
          label={getFieldLabel('nip')}
          name="nip"
          rules={[
            {
              required: isCompanyRequired,
              message: (
                <FormattedMessage defaultMessage="To pole jest wymagane" />
              ),
            },
            () => ({
              validator(_, value) {
                if (!value || isValidNip(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    intl.formatMessage({
                      defaultMessage: 'Podany numer NIP jest niepoprawny',
                    }),
                  ),
                );
              },
            }),
          ]}
        >
          <Input />
        </Item>
      )}
      <Item
        label={getFieldLabel('phone')}
        name="phone"
        rules={[
          {
            required: isCompanyRequired,
            message: (
              <FormattedMessage defaultMessage="To pole jest wymagane" />
            ),
          },
          validatePhoneNumberRule(),
        ]}
      >
        <Input />
      </Item>
    </FormCommon>
  );
};

export default FormCompany;
