import { Form, Button, notification, FormInstance } from 'antd';
import cn from 'classnames';
import { useEffect, ReactElement, ReactNode, useState, FormEvent } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';
import {
  useGetCurrentUserQuery,
  useUpdateUserMutation,
} from 'ducks/user/service';
import { SetUserRequest, UserFormType } from 'ducks/user/types';
import useViewport from 'hooks/useViewport';
import styles from './styles.module.scss';

const { Item, useForm } = Form;

const labelCol = 8;
const wrapperCol = 16;

export type WrapperConfig = undefined | { offset: number; span: number };

type Props = {
  children: ReactNode;
  type: UserFormType;
  getFieldLabel: (fieldName: string) => ReactElement | string;
  onWrapperConfigChange?: (args: WrapperConfig) => void;
  getForm?: (form: FormInstance) => void;
};

const FormCommon: React.FC<Props> = ({
  children,
  type,
  getFieldLabel,
  onWrapperConfigChange,
  getForm,
}) => {
  const [noChanges, setNoChanges] = useState(true);
  const [notify, contextHolder] = notification.useNotification();
  const { width } = useViewport();
  const [form] = useForm();
  const { data, isLoading } = useGetCurrentUserQuery();
  const [
    updateUser,
    { isLoading: isSaving, isError: isSaveError, isSuccess: isSaveSuccess },
  ] = useUpdateUserMutation();

  const isWidthSmall = width < 1400;
  const wrapperColConfig = isWidthSmall
    ? undefined
    : { offset: labelCol, span: wrapperCol };

  useEffect(() => {
    if (form && getForm) getForm(form);
  }, [form, getForm]);

  useEffect(() => {
    if (onWrapperConfigChange) onWrapperConfigChange(wrapperColConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperColConfig]);

  // handle save user response
  useEffect(() => {
    if (isSaving || !form) return;

    if (isSaveError) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas zapisu." />
        ),
        description: (
          <FormattedMessage defaultMessage="Podczas zapisu danych wystąpił błąd, spróbuj ponownie. Jeżeli uważasz że to błąd, zgłoś zaistniałą sytuację w BOK" />
        ),
      });
    }

    if (isSaveSuccess) {
      setNoChanges(true);
      notify.success({
        message: <FormattedMessage defaultMessage="Dane zapisano." />,
        description: (
          <FormattedMessage defaultMessage="Wszystkie dane zostały zaktualizowane." />
        ),
      });
    }
  }, [form, isSaveError, isSaveSuccess, isSaving, notify]);

  // populate form
  useEffect(() => {
    if (!data || !form) return;

    form.setFieldsValue(data);
  }, [data, form]);

  // handle save form
  const onFinish = (values: SetUserRequest) => {
    if (!data) return;

    // eslint-disable-next-line no-param-reassign
    values.type = type;

    updateUser(values);
  };

  // handle form validation error
  const onFinishFailed = ({ errorFields }: any) => {
    notify.error({
      message: (
        <FormattedMessage defaultMessage="Błędnie wypełniony formularz." />
      ),
      description: (
        <FormattedMessage
          defaultMessage="Popraw następujące pola: {fields}"
          values={{
            fields: (
              <FormattedList
                type="conjunction"
                value={errorFields.map(({ name }: { name: string[] }) =>
                  getFieldLabel(name[0].toString()),
                )}
              />
            ),
          }}
        />
      ),
    });
  };

  const onChange = () => {
    setNoChanges(false);
  };

  return (
    <>
      {contextHolder}
      <Form
        name="basic"
        form={form}
        labelCol={isWidthSmall ? undefined : { span: labelCol }}
        wrapperCol={isWidthSmall ? undefined : { span: wrapperCol }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onChange={onChange}
        autoComplete="off"
        disabled={isSaving || isLoading}
        validateTrigger="onBlur"
        layout={isWidthSmall ? 'vertical' : 'horizontal'}
      >
        {children}
        <Item wrapperCol={wrapperColConfig}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSaving}
            disabled={noChanges}
            className={cn(styles.button, { [styles.fullButton]: isWidthSmall })}
          >
            <FormattedMessage defaultMessage="Zapisz" />
          </Button>
        </Item>
      </Form>
    </>
  );
};

export default FormCommon;
