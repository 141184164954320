import { List, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { listItems } from './config';
import styles from './styles.module.scss';

const { Title } = Typography;
const { Item: ListItem } = List;
const { Meta: ListItemMeta } = ListItem;

const ExampleTexts = () => {
  return (
    <List
      size="large"
      header={
        <Title level={5} className={styles.title}>
          <FormattedMessage defaultMessage="Przykłady skutecznych wytycznych do stworzenia treści" />
        </Title>
      }
      footer={null}
      bordered
      dataSource={listItems}
      renderItem={({ title, description }) => (
        <ListItem>
          <ListItemMeta title={title} description={description} />
        </ListItem>
      )}
    />
  );
};

export default ExampleTexts;
