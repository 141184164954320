import { Button, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactElement } from 'react';
import { GoCloudDownload } from 'react-icons/go';
import { HiOutlineRefresh } from 'react-icons/hi';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import FormatDate from 'components/ui/atoms/FormatDate';
import { TableRow } from 'components/ui/molecules/Table';
import { InvoiceStatus } from 'ducks/invoice/types';
import {
  OperationType,
  PaymentDetails,
  PaymentStatus,
} from 'ducks/payment/types';
import { getOperationLabel } from 'ducks/payment/utils';

export type PaymentRow = TableRow & {
  id: number;
  amount: number;
  createdAt: string;
  type: OperationType;
  paymentStatus: PaymentStatus;
  paymentId: string;
  details: PaymentDetails;
};

const getPaymentStatusTag = (status: PaymentStatus): ReactElement | string =>
  ({
    [PaymentStatus.Paid]: (
      <Tag color="success">
        <FormattedMessage defaultMessage="Zrealizowana" />
      </Tag>
    ),
    [PaymentStatus.Correct]: (
      <Tag color="success">
        <FormattedMessage defaultMessage="Zrealizowana" />
      </Tag>
    ),
    [PaymentStatus.Error]: (
      <Tag color="error">
        <FormattedMessage defaultMessage="Błąd płatności" />
      </Tag>
    ),
    [PaymentStatus.Pending]: (
      <Tag color="warning">
        <FormattedMessage defaultMessage="W trakcie" />
      </Tag>
    ),
    [PaymentStatus.Refund]: (
      <Tag color="warning">
        <FormattedMessage defaultMessage="Zwrot środków" />
      </Tag>
    ),
  }[status] || '');

export const columns = (
  onClickDownload: (id: number) => void,
  onClickRecreatePayment: (id: number) => void,
): ColumnsType<PaymentRow> => [
  {
    title: <FormattedMessage defaultMessage="Typ operacji" />,
    key: 'name',
    dataIndex: 'name',
    render: (createdAt, { type, details }) => getOperationLabel(type, details),
  },
  {
    title: <FormattedMessage defaultMessage="Data płatności" />,
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (createdAt) => <FormatDate date={createdAt} />,
  },
  {
    title: <FormattedMessage defaultMessage="Status płatności" />,
    key: 'paymentStatus',
    dataIndex: 'paymentStatus',
    render: (paymentStatus, { type }) =>
      type === OperationType.Payment
        ? getPaymentStatusTag(paymentStatus)
        : getPaymentStatusTag(PaymentStatus.Paid),
  },
  {
    title: <FormattedMessage defaultMessage="Kwota" />,
    key: 'amount',
    dataIndex: 'amount',
    align: 'right',
    render: (amount, { type }) => (
      <FormattedNumber
        value={type === OperationType.OrderDocument ? amount * -1 : amount}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency="PLN"
      />
    ),
  },
  {
    key: 'action',
    width: 50,
    render: ({ key, id, paymentStatus, invoice, type }) => {
      if (paymentStatus === PaymentStatus.Error)
        return (
          <Button
            data-testid={`button-recreate-payment-${key}`}
            type="link"
            icon={<HiOutlineRefresh />}
            onClick={() => onClickRecreatePayment(id)}
          >
            <FormattedMessage defaultMessage="Ponów płatność" />
          </Button>
        );

      if (
        paymentStatus !== PaymentStatus.Paid ||
        type !== OperationType.Payment
      )
        return null;

      if (invoice?.status === InvoiceStatus.Ready)
        return (
          <Button
            data-testid={`button-download-${key}`}
            type="link"
            icon={<GoCloudDownload />}
            onClick={() => onClickDownload(id)}
          >
            <FormattedMessage defaultMessage="Pobierz fakturę" />
          </Button>
        );

      return (
        <Button
          data-testid={`button-invoice-generating-${key}`}
          type="link"
          icon={<GoCloudDownload />}
          disabled
        >
          <FormattedMessage defaultMessage="Generowanie faktury" />
        </Button>
      );
    },
  },
];
