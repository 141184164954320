import { FormattedMessage } from 'react-intl';

export const languagesOptions = [
  {
    value: 'pl',
    label: <FormattedMessage defaultMessage="Polski" />,
  },
  {
    value: 'en',
    label: <FormattedMessage defaultMessage="Angielski" />,
  },
  {
    value: 'de',
    label: <FormattedMessage defaultMessage="Niemiecki" />,
  },
  {
    value: 'ua',
    label: <FormattedMessage defaultMessage="Ukraiński" />,
  },
];
