import { Row, Col, Card, Avatar, Skeleton } from 'antd';
import { FormattedMessage } from 'react-intl';
import BgProfile from 'assets/images/bg-profile.jpg';
import { useGetCurrentUserQuery } from 'ducks/user/service';
import DeleteAccount from './components/DeleteAccount';
import FormBasic from './components/FormBasic';
import FormCompany from './components/FormCompany';
import ResetPassword from './components/ResetPassword';

const Profile: React.FC = () => {
  const { data, isFetching } = useGetCurrentUserQuery();

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: `url(${BgProfile})` }}
      />

      <Card
        className="card-profile-head"
        bodyStyle={{ display: 'none' }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Skeleton
                  loading={isFetching}
                  round
                  title={false}
                  paragraph={false}
                  avatar={{ shape: 'square', size: 74 }}
                  active
                  className="ant-avatar"
                >
                  <Avatar
                    size={74}
                    shape="square"
                    src={data ? data.avatarUrl : ''}
                  >
                    {!!data?.firstName &&
                      data.firstName.slice(0, 2).toLocaleUpperCase()}
                  </Avatar>
                </Skeleton>

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">
                    <Skeleton
                      loading={isFetching}
                      round
                      title={{ width: '250px' }}
                      paragraph={false}
                      active
                    >
                      {data
                        ? `${data.firstName || ''} ${data.lastName || ''}`
                        : ''}
                    </Skeleton>
                  </h4>
                  <div className="subtitle">
                    <Skeleton
                      loading={isFetching}
                      round
                      title={false}
                      active
                      paragraph={{ rows: 1, width: '150px' }}
                    >
                      {data ? data.email : ''}
                    </Skeleton>
                  </div>
                </div>
              </Avatar.Group>
            </Col>
          </Row>
        }
      />

      <Row gutter={[24, 0]}>
        <Col span={24} md={8} className="mb-24 ">
          <Card
            bordered={false}
            className="header-solid h-full"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            title={<FormattedMessage defaultMessage="Dane podstawowe konta" />}
          >
            <FormBasic />
          </Card>
        </Col>
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={
              <FormattedMessage defaultMessage="Dane rozliczeniowe (do faktury VAT)" />
            }
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <FormCompany />
          </Card>
        </Col>
        <Col span={24} md={8} className="mb-24">
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Card
                bordered={false}
                title={<FormattedMessage defaultMessage="Zmiana hasła" />}
                className="header-solid h-full"
                bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <ResetPassword isSocial={data?.isSocial || false} />
              </Card>
            </Col>
            <Col xs={24}>
              <Card
                bordered={false}
                title={<FormattedMessage defaultMessage="Usuwanie konta" />}
                className="header-solid h-full"
                bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <DeleteAccount />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
