/* eslint-disable import/no-extraneous-dependencies */
import ReactQuill from 'react-quill';
import styles from './style.module.scss';
import 'react-quill/dist/quill.snow.css';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const Editor: React.FC<Props> = ({ value, onChange }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ color: [] }, { background: [] }],
      ['clean'],
    ],
  };

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      className={styles.editor}
      modules={modules}
    />
  );
};

export default Editor;
