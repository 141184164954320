/* eslint-disable react/style-prop-object */
import { Button, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AiOutlineSearch } from 'react-icons/ai';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import FormatDate from 'components/ui/atoms/FormatDate';
import { TableRow } from 'components/ui/molecules/Table';
import { Payment } from 'ducks/payment/types';
import { emptyDataPlaceholder } from 'helpers/consts';
import { getDocumentStatusTag } from 'utilities/documents';
import styles from './styles.module.scss';
import { DocumentFlags } from 'ducks/document/types';

export type DocumentRow = TableRow & {
  charge?: Payment;
  saved: number;
  date: string;
  title: string;
  project?: string | null;
  headers: string[] | null;
  phrases: string[] | null;
  characters: number;
  key: number;
  id: number;
  flags?: DocumentFlags[];
};

export const columns = (
  onClickView: (id: number) => void,
): ColumnsType<DocumentRow> => [
  {
    title: <FormattedMessage defaultMessage="Data" />,
    key: 'date',
    dataIndex: 'date',
    render: (date) => <FormatDate date={date} />,
  },
  {
    title: <FormattedMessage defaultMessage="Projekt" />,
    key: 'project',
    dataIndex: 'project',
    render: (project) => project ?? emptyDataPlaceholder,
  },
  {
    title: <FormattedMessage defaultMessage="Szczegóły zamówienia" />,
    key: 'title',
    dataIndex: 'title',
    render: (title, { headers, phrases, characters, id }) => (
      <ul>
        <li>
          <strong>
            <FormattedMessage defaultMessage="Tytuł" />:
          </strong>
          {` ${title}`}
        </li>
        <li>
          <strong>
            <FormattedMessage defaultMessage="Kluczowe frazy" />:
          </strong>
          {` `}
          {!phrases ? (
            emptyDataPlaceholder
          ) : (
            <>
              {phrases.map((phrase: string) => (
                <Tag key={`${id}-${phrase}`}>{phrase}</Tag>
              ))}
            </>
          )}
        </li>
        <li>
          <strong>
            <FormattedMessage defaultMessage="Nagłówki" />:
          </strong>
          {` `}
          {!headers ? (
            emptyDataPlaceholder
          ) : (
            <>
              {headers.map((header: string) => (
                <Tag key={`${id}-${header}`}>{header}</Tag>
              ))}
            </>
          )}
        </li>
        <li>
          <strong>
            <FormattedMessage defaultMessage="Liczba znaków" />:
          </strong>
          {` ${characters}`}
        </li>
      </ul>
    ),
  },
  {
    title: <FormattedMessage defaultMessage="Status" />,
    key: 'status',
    dataIndex: 'status',
    render: (status, { flags }) => getDocumentStatusTag(status, flags),
  },
  {
    title: <FormattedMessage defaultMessage="Cena" />,
    key: 'price',
    dataIndex: 'price',
    align: 'right',
    render: (price, { charge, saved }) => (
      <Space direction="vertical">
        {charge && (
          <FormattedNumber
            value={charge?.amount}
            style="currency"
            currency="PLN"
          />
        )}
        <span className={styles.saved}>
          <FormattedMessage defaultMessage="zaoszczędziłeś" />{' '}
          <FormattedNumber value={saved} style="currency" currency="PLN" />
        </span>
      </Space>
    ),
  },
  {
    key: 'action',
    width: 50,
    render: ({ key, id }) => (
      <Button
        data-testid={`button-edit-${key}`}
        type="link"
        icon={<AiOutlineSearch />}
        onClick={() => onClickView(id)}
      >
        <FormattedMessage defaultMessage="Podgląd" />
      </Button>
    ),
  },
];
