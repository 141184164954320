import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Space, Tag } from 'antd';
import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

type Props = {
  value?: string[];
  onChange?: (value: string[]) => void;
};

const TagInput: React.FC<Props> = ({ onChange }) => {
  const ref = useRef<InputRef>(null);
  const [tags, setTags] = useState<string[]>([]);
  const [currentValue, setCurrentValue] = useState<string>('');

  const addNewTag = (newTag: string) => {
    setTags((prevVal) =>
      !prevVal.includes(newTag) && newTag ? [...prevVal, newTag] : [...prevVal],
    );
    setCurrentValue('');
  };

  const handleTagClose = (tag: string) => {
    setTags(tags.filter((tagFilter) => tagFilter !== tag));
  };

  useEffect(() => {
    if (onChange) onChange(tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className={styles.wrapper}
      data-testid="tagInput"
      onClick={() => {
        if (!ref.current) return;
        ref?.current?.focus();
      }}
    >
      <div
        className={styles.tagsWrapper}
        style={{
          marginLeft: tags.length ? '5px' : '0',
        }}
      >
        {tags.map((tag) => (
          <Tag closable onClose={() => handleTagClose(tag)} key={tag}>
            {tag}
          </Tag>
        ))}
      </div>
      <Space.Compact style={{ width: '100%' }}>
        <Input
          data-testid="tagInput-input"
          ref={ref}
          onChange={(e) => setCurrentValue(e.target.value)}
          value={currentValue}
          onPressEnter={(e: any) => addNewTag(e.target.value)}
        />
        <Button
          type="primary"
          onClick={() => addNewTag(currentValue)}
          className={styles.icon}
          icon={<PlusCircleOutlined />}
        />
      </Space.Compact>
    </div>
  );
};

export default TagInput;
