export const getTermsAndConditionsParams = () => {
  const isTermsAndConditionsRoute =
    window.location.pathname === '/accept-terms';
  if (!isTermsAndConditionsRoute) return false;

  const params = new URLSearchParams(window.location.search);
  const state = params.get('state');
  const domain = params.get('auth0_domain');

  if (!state || !domain) return false;

  return { state, domain };
};
