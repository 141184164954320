/* eslint-disable import/no-extraneous-dependencies */
import { Card, Empty, Table as AntdTable, TablePaginationConfig } from 'antd';
import { TableProps } from 'antd/lib/table';
import cn from 'classnames';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { TableRow } from './config';

import styles from './styles.module.scss';
import { getTableSkeleton } from './utils';

interface Props<T> extends TableProps<T> {
  data: T[];
  isLoading?: boolean;
  cardHeader?: ReactElement | ReactElement[];
  emptyText?: ReactElement | string | undefined;
  pagination?: TablePaginationConfig;
}

const Table = <T extends TableRow>({
  data,
  columns,
  cardHeader,
  rowSelection,
  onRow,
  emptyText,
  rowClassName,
  pagination,
  isLoading = false,
  showHeader = true,
}: Props<T>): JSX.Element => {
  const locale = useMemo(
    () => ({
      emptyText: emptyText || (
        <Empty
          description={<FormattedMessage defaultMessage="Brak rekordów" />}
        />
      ),
    }),
    [emptyText],
  );

  const skeletonData = useMemo(() => {
    const columnNames = columns?.map(({ key }) => key as string) || [];
    return getTableSkeleton(columnNames, 3);
  }, [columns]);

  const rawColumns = useMemo(() => {
    return (
      columns?.map((column) => {
        if (column.render) {
          const columnCopy = { ...column };
          delete columnCopy.render;
          return columnCopy;
        }
        return column;
      }) || []
    );
  }, [columns]);

  return (
    <Card bordered={false} className="criclebox cardbody h-full">
      <div className={styles.header}>{cardHeader}</div>
      <div className="ant-list-box table-responsive">
        <AntdTable
          className={cn('ant-border-space', { [styles.rowPointer]: !!onRow })}
          rowClassName={rowClassName}
          dataSource={isLoading ? skeletonData : data}
          columns={isLoading ? rawColumns : columns}
          rowSelection={rowSelection}
          showHeader={showHeader}
          pagination={{
            hideOnSinglePage: true,
            position: ['bottomCenter'],
            ...pagination,
          }}
          onRow={onRow}
          locale={locale}
          data-testid="table"
        />
      </div>
    </Card>
  );
};

export default Table;
