import { Button, Card, Empty, Skeleton, Timeline, Typography } from 'antd';

import { FaMoneyBillWaveAlt } from 'react-icons/fa';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'components/main/App/Routing';
import FormatDate from 'components/ui/atoms/FormatDate';
import { useGetPaymentsQuery } from 'ducks/payment/service';
import { OperationType } from 'ducks/payment/types';
import { getOperationLabel } from 'ducks/payment/utils';

const { Title, Text } = Typography;

const PaymentsHistory: React.FC = () => {
  const navigate = useNavigate();

  const { data, isFetching } = useGetPaymentsQuery({
    limit: 10,
    order: 'desc',
  });

  return (
    <Card bordered={false} className="criclebox h-full">
      <div className="timeline-box">
        <Title level={5} style={{ marginBottom: 24 }}>
          <FormattedMessage defaultMessage="Historia Twoich zamówień" />
        </Title>

        <Timeline className="timelinelist">
          {isFetching &&
            Array.from({ length: 4 }, (_, idx) => (
              <Timeline.Item key={idx}>
                <Skeleton title={false} paragraph={{ rows: 2 }} active />
              </Timeline.Item>
            ))}
          {data?.length === 0 && (
            <Empty
              className="mb-25"
              description={
                <FormattedMessage defaultMessage="Wygląda na to, że jeszcze nie dokonałeś żadnej wpłaty." />
              }
            />
          )}
          {data &&
            data.map(({ amount, createdAt, type, details }, idx) => (
              <Timeline.Item
                color={type === OperationType.Payment ? 'green' : undefined}
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
              >
                <Title level={5}>
                  {
                    <FormattedNumber
                      value={OperationType.Payment ? amount : amount * -1}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      currency="PLN"
                    />
                  }
                  {' - '}
                  {getOperationLabel(type, details)}
                </Title>
                <Text>
                  <FormatDate date={createdAt} />
                </Text>
              </Timeline.Item>
            ))}
        </Timeline>
        <Button
          type="primary"
          className="width-100"
          onClick={() => navigate(Routes.PAYMENTS)}
        >
          <FaMoneyBillWaveAlt />{' '}
          <FormattedMessage defaultMessage="Faktury i płatności" />
        </Button>
      </div>
    </Card>
  );
};

export default PaymentsHistory;
