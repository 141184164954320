import { SearchOutlined } from '@ant-design/icons';
import { Row, Col, Breadcrumb, Button, Input, Avatar } from 'antd';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoLogOut } from 'react-icons/io5';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, Link } from 'react-router-dom';
import { Routes } from 'components/main/App/Routing';
import { LogoutButton } from 'components/main/AuthProvider';
import { useGetCurrentUserQuery } from 'ducks/user/service';

type Props = {
  showSearch?: boolean;
  name: string;
  subName: string;
  toggleSidenavMobileOpened: () => void;
};

const Header: React.FC<Props> = ({
  name,
  subName,
  showSearch = false,
  toggleSidenavMobileOpened,
}) => {
  const intl = useIntl();
  const { data: userData } = useGetCurrentUserQuery();

  return (
    <Row gutter={[24, 0]}>
      <Col span={24} md={6}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to="/">
              <FormattedMessage defaultMessage="Główna" />
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{name}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="ant-page-header-heading">
          <span className="ant-page-header-heading-title">{subName}</span>
        </div>
      </Col>
      <Col span={24} md={18} className="header-control">
        <Button
          type="link"
          className="sidebar-toggler"
          onClick={toggleSidenavMobileOpened}
        >
          <GiHamburgerMenu />
        </Button>

        <LogoutButton icon={<IoLogOut />} className="btn-sign-in" type="link">
          <FormattedMessage defaultMessage="Wyloguj się" />
        </LogoutButton>

        {!!userData && (
          <>
            <Link to={Routes.PROFILE} className="btn-sign-in">
              <FormattedMessage
                defaultMessage="Cześć {username}"
                values={{ username: userData.firstName }}
              />
            </Link>
            <Avatar src={userData.avatarUrl}>
              {!!userData.firstName &&
                userData.firstName.slice(0, 2).toLocaleUpperCase()}
            </Avatar>
          </>
        )}

        {showSearch && (
          <Input
            className="header-search"
            placeholder={intl.formatMessage({ defaultMessage: 'Wyszukaj...' })}
            prefix={<SearchOutlined />}
          />
        )}
      </Col>
    </Row>
  );
};

export default Header;
