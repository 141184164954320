import { useState } from 'react';

type CopiedValue = string | null;
type CopyToClipboardFn = (text: string) => Promise<boolean>;

const useCopyToClipboard = (): [CopiedValue, CopyToClipboardFn] => {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copyToClipboard: CopyToClipboardFn = async (text) => {
    if (!navigator?.clipboard) {
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copyToClipboard];
};

export default useCopyToClipboard;
