import { connect } from 'react-redux';
import { setSidenavMobileOpened } from 'ducks/config/slice';
import { RootState } from 'ducks/store';

const mapStateToProps = ({
  config: { headerFixed, sidenavMobileOpened, sidenavColor, layoutRtl },
}: RootState) => ({
  headerFixed,
  sidenavMobileOpened,
  sidenavColor,
  layoutRtl,
});

const mapDispatchToProps = {
  setSidenavMobileOpened,
};

const storeConnection = connect(mapStateToProps, mapDispatchToProps);
export default storeConnection;
