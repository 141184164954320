import type {
  FormListData,
  FormListItemRecord,
} from 'components/ui/molecules/FormList/config';
import { Form, Input, Skeleton, Space } from 'antd';
import { cloneElement, Fragment } from 'react';
import styles from './styles.module.scss';

interface Props<TData> extends FormListItemRecord<TData> {
  data: FormListData<TData>;
  isLoading: boolean;
}

const { Item } = Form;

const FormListItem = <TData,>({ data, cellItems, isLoading }: Props<TData>) => {
  return (
    <Skeleton
      loading={isLoading}
      round
      paragraph={false}
      active
      className={styles.skeleton}
    >
      {cellItems(data).map(({ name, render, rules, ...rest }, index) => (
        <Fragment key={name ?? index}>
          {!name && !!render && cloneElement(render)}
          {name && (
            <Item
              name={[data.id, name]}
              className={styles.item}
              initialValue={data[name]}
              rules={rules}
              {...rest}
            >
              {render ? (
                cloneElement(render, {
                  className: 'width-100',
                })
              ) : (
                <Input className="width-100" />
              )}
            </Item>
          )}
        </Fragment>
      ))}
    </Skeleton>
  );
};

export default FormListItem;
