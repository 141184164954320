import { Col, Row } from 'antd';
import Documents from './components/Documents';
import InfoBoxes from './components/InfoBoxes/InfoBoxes';
import PaymentsHistory from './components/PaymentsHistory';

const Dashboard: React.FC = () => {
  return (
    <div className="layout-content">
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        <InfoBoxes />
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
          <Documents />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
          <PaymentsHistory />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
