import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';

import { TableRow } from 'components/ui/molecules/Table';
import SecretField from 'views/License/components/SecretField';

export type LicenseRow = TableRow & {
  id: number;
  name: string;
  key: string;
  secret: string;
};

export const columns = (
  onClickEdit: (id: number) => void,
  onClickDelete: (id: number) => void,
): ColumnsType<LicenseRow> => [
  {
    title: <FormattedMessage defaultMessage="Nazwa" />,
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: <FormattedMessage defaultMessage="Key" />,
    key: 'keySource',
    dataIndex: 'keySource',
    render: (_, record) => <SecretField value={record.key} />,
  },
  {
    title: <FormattedMessage defaultMessage="Secret" />,
    key: 'secret',
    dataIndex: 'secret',
    render: (_, record) => <SecretField value={record.secret} />,
  },
  {
    key: 'action',
    width: 50,
    render: ({ key, id }) => (
      <Space size="middle" data-testid="buttons-actions">
        <Button
          data-testid={`button-edit-${key}`}
          type="link"
          icon={<AiFillEdit />}
          onClick={() => onClickEdit(id)}
        >
          <FormattedMessage defaultMessage="Edytuj" />
        </Button>
        <Button
          data-testid={`button-delete-${key}`}
          type="link"
          icon={<AiFillDelete />}
          onClick={() => onClickDelete(id)}
        >
          <FormattedMessage defaultMessage="Usuń" />
        </Button>
      </Space>
    ),
  },
];
