import { useMemo } from 'react';
import {
  useCalculateDocumentMutation,
  useCreateDocumentMutation,
  useGetDocumentTemplatesQuery,
} from 'ducks/document/service';
import { getCopywriterPrice } from 'utilities/utilities';
import useButtonState from 'views/Documents/hooks/useButtonState';
import { Form } from 'antd';

const { useForm } = Form;

const useManageGenerator = () => {
  const [form] = useForm();

  // api
  const { data: templatesData = [], isLoading: templatesLoading } =
    useGetDocumentTemplatesQuery();

  const [calculateDocument, { isLoading, data: calculateResponse }] =
    useCalculateDocumentMutation();

  const [
    createDocument,
    {
      isLoading: createDocumentLoading,
      isSuccess: createDocumentSuccess,
      isError: createDocumentError,
      error: createDocumentErrorData,
    },
  ] = useCreateDocumentMutation();

  // state
  const { buttonState, setButtonState, shouldRenderPrice } = useButtonState();

  // functions

  const handleFormSubmit = async () => {
    const formValues = form.getFieldsValue();

    if (buttonState === 'filled') {
      setButtonState('submitted');
      await calculateDocument({ characters: formValues.characters });
    } else if (shouldRenderPrice) {
      setButtonState('final');

      const dataToSend = {
        query: formValues.query,
        name: formValues.name,
        characters: formValues.characters,
        template_id: formValues.template_id,
      };

      await createDocument(dataToSend);
    }
  };

  const handleEdit = (values: {
    query: string;
    characters: string;
    name: string;
    template_id: string;
  }) => {
    if (values.query && values.characters && values.name && values.template_id)
      setButtonState('filled');
    else setButtonState('clean');
  };

  // memoized state

  const copywriterPrice = useMemo(() => {
    const formValues = form.getFieldsValue();
    const price = calculateResponse?.price ?? 1;
    return getCopywriterPrice({ characters: formValues.characters, price });
  }, [calculateResponse, form]);

  const balance = useMemo(
    () =>
      calculateResponse
        ? calculateResponse.balance - calculateResponse.price
        : null,
    [calculateResponse],
  );

  const templatesOptions = useMemo(() => {
    return templatesData?.map((template) => ({
      value: template.id,
      label: template.name,
    }));
  }, [templatesData]);

  return {
    shouldRenderPrice,
    balance,
    copywriterPrice,
    handleFormSubmit,
    buttonState,
    isLoading,
    calculateResponse,
    createDocumentLoading,
    createDocumentSuccess,
    createDocumentError,
    createDocumentErrorData,
    form,
    handleEdit,
    templatesOptions,
    templatesLoading,
  };
};

export default useManageGenerator;
