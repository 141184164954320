import { Button, Card, Empty, Skeleton, Typography } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import FormatDate from 'components/ui/atoms/FormatDate';
import { useGetDocumentsQuery } from 'ducks/document/service';
import { emptyDataPlaceholder } from 'helpers/consts';
import { getDocumentStatusTag, getListItem } from 'utilities/documents';
import { Routes, getFullPath } from 'views/Documents/Routing';
import styles from './styles.module.scss';

const { Title } = Typography;

const Documents: React.FC = () => {
  const navigate = useNavigate();
  const { data, isFetching } = useGetDocumentsQuery({
    limit: 10,
    order: 'desc',
  });

  return (
    <Card bordered={false} className="criclebox cardbody h-full">
      <div className="project-ant">
        <Title level={5}>
          <FormattedMessage defaultMessage="Twoje treści" />
        </Title>
      </div>
      <div className="ant-list-box table-responsive">
        <table className="width-100">
          <thead>
            <tr>
              <th>
                <FormattedMessage defaultMessage="Wytyczne" />
              </th>
              <th>
                <FormattedMessage defaultMessage="Projekt" />
              </th>
              <th>
                <FormattedMessage defaultMessage="Status" />
              </th>
              <th>
                <FormattedMessage defaultMessage="Data zamówienia" />
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {isFetching &&
              Array.from({ length: 4 }, (_, idx) => (
                <tr key={idx}>
                  <td>
                    <Skeleton title={false} paragraph={{ rows: 1 }} active />
                  </td>
                  <td>
                    <Skeleton title={false} paragraph={{ rows: 1 }} active />
                  </td>
                  <td>
                    <Skeleton title={false} paragraph={{ rows: 1 }} active />
                  </td>
                  <td>
                    <Skeleton title={false} paragraph={{ rows: 1 }} active />
                  </td>
                  <td> </td>
                </tr>
              ))}
            {data?.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <Empty
                    className="mb-25"
                    description={
                      <FormattedMessage defaultMessage="Nie wygenerowałeś jeszcze żadnego dokumentu." />
                    }
                  />
                </td>
              </tr>
            )}
            {data &&
              data.map(
                ({
                  characters,
                  license,
                  status,
                  createdAt,
                  id,
                  name,
                  flags,
                }) => (
                  <tr key={id}>
                    <td>
                      <ul className={styles.list}>
                        {getListItem(
                          <FormattedMessage defaultMessage="Nazwa" />,
                          name,
                        )}
                        {getListItem(
                          <FormattedMessage defaultMessage="Liczba znaków" />,
                          <FormattedNumber value={characters} />,
                        )}
                      </ul>
                    </td>
                    <td>{license?.name || emptyDataPlaceholder}</td>
                    <td>{getDocumentStatusTag(status, flags)}</td>
                    <td>
                      <FormatDate date={createdAt} />
                    </td>
                    <td>
                      <Button
                        type="link"
                        icon={<AiOutlineSearch />}
                        onClick={() =>
                          navigate(
                            getFullPath(
                              Routes.EDIT.replace(':documentId', `${id}`),
                            ),
                          )
                        }
                      >
                        <FormattedMessage defaultMessage="Podgląd" />
                      </Button>
                    </td>
                  </tr>
                ),
              )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default Documents;
