import { DefaultResponse } from 'ducks/types';

export enum LicenseTag {
  List = 'List',
}

export type License = {
  id: number;
  name: string;
  secret: string;
  key: string;
};

export type CreateLicenseRequest = Omit<
  Partial<License>,
  'secret' | 'key' | 'id'
>;

export type CreateLicenseResponse = DefaultResponse & License;

export type UpdateLicenseRequest = {
  id: string;
  name: string;
};
