import { Tag } from 'antd';
import { ReactElement } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import FormatDate from 'components/ui/atoms/FormatDate';
import { Document } from 'ducks/document/types';
import { emptyDataPlaceholder } from 'helpers/consts';

type DocumentParam = {
  title: string | ReactElement;
  description: string | number | ReactElement;
};

const prepareTagList = (list: string[] | undefined | null): ReactElement => {
  if (!list?.length) return <span>{emptyDataPlaceholder}</span>;

  return (
    <>
      {(list || []).map((elem) => (
        <Tag key={elem}>{elem}</Tag>
      ))}
    </>
  );
};

export const getDocumentParams = (
  data: Document | undefined,
): DocumentParam[] => {
  if (!data) return [];

  const params = [
    {
      title: <FormattedMessage defaultMessage="Tytuł" />,
      description: data.name,
    },
    {
      title: <FormattedMessage defaultMessage="Utworzony" />,
      description: <FormatDate date={data.createdAt} />,
    },
    {
      title: <FormattedMessage defaultMessage="Liczba znaków" />,
      description: <FormattedNumber value={data.characters} />,
    },
    {
      title: <FormattedMessage defaultMessage="Licencja" />,
      description: data.license?.name || emptyDataPlaceholder,
    },
  ];

  if (data.query) {
    params.push({
      title: <FormattedMessage defaultMessage="Treść zapytania" />,
      description: data.query,
    });
  } else {
    params.push(
      {
        title: <FormattedMessage defaultMessage="Kluczowe frazy" />,
        description: prepareTagList(data.phrases),
      },
      {
        title: <FormattedMessage defaultMessage="Nagłówki" />,
        description: prepareTagList(data.headers),
      },
    );
  }

  return params;
};
