import { Navigate, Route } from 'react-router-dom';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import Dashboard from 'views/Dashboard';
import Documents from 'views/Documents';
import License from 'views/License';
import Payments from 'views/Payments';
import Profile from 'views/Profile';
import ProtectedRoutes from './ProtectedRoutes';
import { notification } from 'antd';

export enum Routes {
  DASHBOARD = '/dashboard',
  PAYMENTS = '/payments',
  DOCUMENTS = '/documents',
  LICENSES = '/licenses',
  PROFILE = '/profile',
}

const Routing: React.FC = () => {
  const [notify, contextHolder] = notification.useNotification({
    maxCount: 1,
  });
  return (
    <>
      {contextHolder}
      <AnimatedRoutes pathLevelWatch={1}>
        <Route path={`${Routes.DASHBOARD}/*`} element={<Dashboard />} />
        <Route path={`${Routes.PROFILE}/*`} element={<Profile />} />
        <Route element={<ProtectedRoutes notify={notify} />}>
          <Route path={`${Routes.PAYMENTS}/*`} element={<Payments />} />
          <Route path={`${Routes.LICENSES}/*`} element={<License />} />
          <Route path={`${Routes.DOCUMENTS}/*`} element={<Documents />} />
        </Route>
        <Route index element={<Navigate replace to={Routes.DASHBOARD} />} />
      </AnimatedRoutes>
    </>
  );
};

export default Routing;
