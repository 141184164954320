import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { config } from './config/slice';
import { ConfigState } from './config/types';
import { documentApi } from './document/service';
import { invoiceApi } from './invoice/service';
import { licenseApi } from './licenses/service';
import { paymentApi } from './payment/service';
import { userApi } from './user/service';

export type RootState = {
  config: ConfigState;
};

const store = configureStore({
  reducer: {
    config,
    [userApi.reducerPath]: userApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [licenseApi.reducerPath]: licenseApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      paymentApi.middleware,
      documentApi.middleware,
      licenseApi.middleware,
      invoiceApi.middleware,
    ),
});

setupListeners(store.dispatch);

export default store;
