import { Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/ui/atoms/Loader';
import PaymentMethodsField from 'components/ui/atoms/PaymentMethodsField';
import { useCreatePaymentMutation } from 'ducks/payment/service';

const { Item } = Form;

type Props = {
  isOpen: boolean;
  initialAmount?: number;
  onClose: () => void;
};

const PaymentModal: React.FC<Props> = ({ isOpen, onClose, initialAmount }) => {
  const [form] = Form.useForm();
  const [createPayment, { isLoading, data }] = useCreatePaymentMutation();
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    if (!data) return;
    setIsRedirecting(true);

    window.location.href = data.url;
  }, [data]);

  const handleFormSubmit = () => {
    if (isLoading || isRedirecting) return;

    form
      .validateFields()
      .then(createPayment)
      .catch(() => {
        // do nothing
      });
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Doładuj konto" />}
      open={isOpen}
      onCancel={onClose}
      onOk={handleFormSubmit}
      okText={<FormattedMessage defaultMessage="Doładuj konto" />}
      cancelText={<FormattedMessage defaultMessage="Anuluj" />}
    >
      <Loader
        visible={isLoading || isRedirecting}
        text={
          <FormattedMessage defaultMessage="Trwa przekierowanie do serwisu realizującego płatność" />
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ amount: initialAmount }}
        >
          <Item
            label={<FormattedMessage defaultMessage="Kwota doładowania" />}
            name="amount"
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage defaultMessage="Podaj kwotę doładowania" />
                ),
              },
              {
                pattern: /^[0-9]{1,5}$/,
                message: (
                  <FormattedMessage defaultMessage="Podaj właściwą kwotę doładowania bez groszy" />
                ),
              },
            ]}
          >
            <Input addonAfter="zł" />
          </Item>
          <Item
            label={<FormattedMessage defaultMessage="Metoda płatności" />}
            name="groupId"
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage defaultMessage="Wybierz jedną z metod płatności" />
                ),
              },
            ]}
          >
            <PaymentMethodsField />
          </Item>
        </Form>
      </Loader>
    </Modal>
  );
};

export default PaymentModal;
