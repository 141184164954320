import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Select, Tooltip, notification } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'components/main/App/Routing';
import ButtonWrapper from 'views/Documents/components/ButtonWrapper';
import ResponseBoxes from 'views/Documents/components/ResponseBoxes';
import { Routes as DocsRoutes } from 'views/Documents/Routing';
import useManageGenerator from './useManageGenerator';

const { Item } = Form;
const { TextArea } = Input;

const TextAreaForm = () => {
  const [notify, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const {
    shouldRenderPrice,
    balance,
    copywriterPrice,
    handleFormSubmit,
    buttonState,
    isLoading,
    calculateResponse,
    createDocumentLoading,
    createDocumentSuccess,
    createDocumentError,
    createDocumentErrorData,
    form,
    handleEdit,
    templatesOptions,
    templatesLoading,
  } = useManageGenerator();

  useEffect(() => {
    if (createDocumentLoading) return;
    if (createDocumentError) {
      notify.error({
        message: <FormattedMessage defaultMessage="Spróbuj ponownie" />,
      });
    }

    if (createDocumentSuccess) {
      notify.success({
        message: (
          <FormattedMessage defaultMessage="Utworzono pomyślnie dokument" />
        ),
      });
      navigate(`${Routes.DOCUMENTS}${DocsRoutes.LIST}`);
    }
  }, [
    createDocumentLoading,
    createDocumentSuccess,
    createDocumentError,
    notify,
    navigate,
  ]);

  useEffect(() => {
    if (templatesLoading) return;

    form.setFieldsValue({
      template_id: 1,
    });
  }, [templatesLoading, form]);

  return (
    <Form
      form={form}
      initialValues={{
        characters: 1500,
      }}
      onFinish={handleFormSubmit}
      onValuesChange={(changedValue, values) => handleEdit(values)}
      layout="vertical"
    >
      {contextHolder}
      <Item
        name="name"
        label={<FormattedMessage defaultMessage="Nazwa dokumentu" />}
        rules={[
          {
            required: true,
            message: <FormattedMessage defaultMessage="Wprowadz nazwę" />,
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        name="characters"
        label={<FormattedMessage defaultMessage="Liczba znaków" />}
        rules={[
          {
            required: true,
            message: <FormattedMessage defaultMessage="Podaj liczbę znaków" />,
          },
        ]}
      >
        <InputNumber min={1} style={{ width: '100%' }} />
      </Item>

      <Item
        name="template_id"
        label={<FormattedMessage defaultMessage="Szablon tekstu" />}
        rules={[
          {
            required: true,
            message: <FormattedMessage defaultMessage="Uzupełnij szablon" />,
          },
        ]}
      >
        <Select options={templatesOptions} loading={templatesLoading} />
      </Item>

      <Item
        name="query"
        label={
          <span>
            <FormattedMessage defaultMessage="Wytyczne do stworzenia treści" />{' '}
            <Tooltip
              title={
                <FormattedMessage defaultMessage='Jeżeli w treści wytycznych określisz długość tekstu, to uzyskasz niepełny tekst. BusinessWriter.ai wyświetli Ci tekst o zbliżonej liczbie znaków do wartości wprowadzonej w polu "Liczba znaków".' />
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        }
        rules={[
          {
            required: true,
            message: <FormattedMessage defaultMessage="Wprowadz tekst" />,
          },
        ]}
      >
        <TextArea autoSize={{ minRows: 10 }} />
      </Item>
      <ButtonWrapper
        isLoading={isLoading || createDocumentLoading}
        buttonState={buttonState}
        isDisabled={!!balance && balance < 0}
      />
      <ResponseBoxes
        shouldRenderPrice={shouldRenderPrice}
        calculateIsLoading={isLoading}
        copywriterPrice={copywriterPrice}
        calculateData={calculateResponse}
        documentErrorData={createDocumentErrorData}
        balance={balance}
      />
    </Form>
  );
};

export default TextAreaForm;
