import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import {
  BatchDocumentRequest,
  CalculateDocumentBody,
  CalculateDocumentResponse,
  CreateDocumentRequest,
  Document,
  DocumentTag,
  GetDocumentTemplatesResponse,
  GetDocumentsArgs,
  UpdateDocumentRequest,
  UpdateDocumentResponse,
} from './types';

export enum Endpoint {
  GetDocuments = '/document',
  GetDocument = '/document/:documentId',
  UpdateDocument = '/document/:documentId',
  DocumentCalculate = '/document/calculate',
  DocumentCreate = '/document',
  DocumentBatch = '/document/batch',
  GetDocumentTemplates = '/document/templates',
}

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: baseQuery(),
  tagTypes: [DocumentTag.Document],
  endpoints: (build) => ({
    getDocuments: build.query<Document[], GetDocumentsArgs | void>({
      query: () => ({
        url: Endpoint.GetDocuments,
        method: 'GET',
      }),
      transformResponse: (response: Document[], meta, args) => {
        if (!response) return [];

        let mappedResponse = response;

        // order data
        if (args?.order === 'desc') {
          mappedResponse = mappedResponse.sort((a, b) => b.id - a.id);
        }

        // limit data
        if (args?.limit && mappedResponse.length > args.limit) {
          mappedResponse = mappedResponse.slice(0, args.limit);
        }

        return mappedResponse;
      },
      providesTags: [DocumentTag.Document],
    }),
    getDocument: build.query<Document, number>({
      query: (documentId) => ({
        url: Endpoint.GetDocument.replace(':documentId', `${documentId}`),
        method: 'GET',
      }),
      providesTags: [DocumentTag.Document],
    }),
    updateDocument: build.mutation<
      UpdateDocumentResponse,
      UpdateDocumentRequest
    >({
      query: ({ documentId, ...data }) => ({
        url: Endpoint.UpdateDocument.replace(':documentId', `${documentId}`),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: [DocumentTag.Document],
    }),
    calculateDocument: build.mutation<
      CalculateDocumentResponse,
      CalculateDocumentBody
    >({
      query: (params) => ({
        url: Endpoint.DocumentCalculate,
        method: 'POST',
        params,
      }),
      invalidatesTags: [DocumentTag.Document],
    }),
    createDocument: build.mutation<void, CreateDocumentRequest>({
      query: (data) => ({
        url: Endpoint.DocumentCreate,
        method: 'POST',
        data,
      }),
      invalidatesTags: [DocumentTag.Document],
    }),
    batchDocument: build.mutation<void, BatchDocumentRequest>({
      query: (data) => ({
        url: Endpoint.DocumentBatch,
        method: 'POST',
        data,
      }),
      invalidatesTags: [DocumentTag.Document],
    }),
    getDocumentTemplates: build.query<GetDocumentTemplatesResponse, void>({
      query: () => ({
        url: Endpoint.GetDocumentTemplates,
        method: 'GET',
      }),
      providesTags: [DocumentTag.Document],
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentQuery,
  useUpdateDocumentMutation,
  useCalculateDocumentMutation,
  useCreateDocumentMutation,
  useBatchDocumentMutation,
  useGetDocumentTemplatesQuery,
} = documentApi;
