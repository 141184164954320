import { Route } from 'react-router-dom';
import AnimatedRoutes from 'components/main/AnimatedRoutes';
import { Routes as MainRoutes } from 'components/main/App/Routing';
import Creator from './views/Creator';
import Edit from './views/Edit';
import Generator from './views/Generator';
import List from './views/List';

export enum Routes {
  LIST = '',
  CREATOR = '/creator',
  GENERATOR = '/generator',
  ADD = '/add',
  EDIT = '/edit/:documentId',
}

export const getFullPath = (route: string): string =>
  `${MainRoutes.DOCUMENTS}${route}`;

const Routing: React.FC = () => {
  return (
    <AnimatedRoutes pathLevelWatch={2}>
      <Route path={`${Routes.LIST}`} element={<List />} />
      <Route path={`${Routes.EDIT}`} element={<Edit />} />
      <Route path={`${Routes.CREATOR}`} element={<Creator />} />
      <Route path={`${Routes.GENERATOR}`} element={<Generator />} />
    </AnimatedRoutes>
  );
};

export default Routing;
