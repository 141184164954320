import { Card, Col, Row, Typography } from 'antd';
import cn from 'classnames';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.scss';

const { Title } = Typography;

type Props = {
  title: string | ReactElement;
  mainValue: string | ReactElement;
  secondValue: string | ReactElement;
  icon: ReactElement;
  iconLink?: string;
  isSuccess?: boolean | undefined;
};

const InfoBox: React.FC<Props> = ({
  title,
  mainValue,
  secondValue,
  icon,
  iconLink,
  isSuccess,
}) => (
  <Card bordered={false} className="criclebox">
    <div className="number">
      <Row align="middle" gutter={[24, 0]}>
        <Col xs={18}>
          <span>{title}</span>
          <Title level={3}>{mainValue} </Title>
          <p
            className={cn(
              { bnb2: isSuccess },
              { redtext: isSuccess === false },
              style.secondText,
            )}
          >
            {secondValue}
          </p>
        </Col>
        <Col xs={6}>
          {iconLink ? (
            <Link to={iconLink} className={style.link}>
              <div className="icon-box">{icon}</div>
            </Link>
          ) : (
            <div className="icon-box">{icon}</div>
          )}
        </Col>
      </Row>
    </div>
  </Card>
);

export default InfoBox;
