import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import Table from 'components/ui/molecules/Table';
import { useGetDocumentsQuery } from 'ducks/document/service';
import { Routes, getFullPath } from 'views/Documents/Routing';
import { DocumentRow, columns } from './config';
import styles from './styles.module.scss';

const { Title } = Typography;

const List: React.FC = () => {
  const navigate = useNavigate();
  // getting all documents from RTK-query
  const { data, isFetching } = useGetDocumentsQuery({
    order: 'desc',
  });

  const tableData = useMemo(
    () =>
      data?.map((value) => ({
        charge: value?.charge,
        date: value.createdAt,
        project: value?.license?.name,
        title: value.name,
        headers: value.headers,
        phrases: value.phrases,
        characters: value.characters,
        status: value.status,
        saved: value.saved,
        key: value.id,
        id: value.id,
        flags: value?.flags,
      })),
    [data],
  );

  const onClickView = (id: number) => {
    navigate(getFullPath(Routes.EDIT.replace(':documentId', `${id}`)));
  };

  return (
    <div className={cn('layout-content mb-24', styles.noWrap)}>
      <Table<DocumentRow>
        rowClassName={styles.documentRow}
        cardHeader={
          <Row
            justify="space-between"
            align="middle"
            className="width-100 ant-form-item-row"
          >
            <Col sm={8} xs={24}>
              <Title level={5}>
                <FormattedMessage defaultMessage="Twoje treści" />
              </Title>
            </Col>
            <Col sm={16} xs={24}>
              <div className={styles.buttonsWrapper}>
                {/* <Link to={getFullPath(Routes.CREATOR)}>
                  <Button
                    icon={<PlusCircleOutlined />}
                    type="primary"
                    className="width-100"
                  >
                    <FormattedMessage defaultMessage="Prosty kreator treści " />
                  </Button>
                </Link> */}
                <Link to={getFullPath(Routes.GENERATOR)}>
                  <Button
                    icon={<PlusCircleOutlined />}
                    type="primary"
                    className="width-100"
                  >
                    <FormattedMessage defaultMessage="Zaawansowany kreator tekstu" />
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        }
        data={tableData ?? []}
        isLoading={isFetching}
        columns={columns(onClickView)}
      />
    </div>
  );
};

export default List;
