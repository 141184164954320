import { Card, Col, Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import ExampleTexts from './components/ExampleTexts';
import TextAreaForm from './components/TextAreaForm';

const { Title } = Typography;

const Generator = () => {
  return (
    <div className="layout-content mb-24">
      <Card bordered={false}>
        <Title level={3}>
          <FormattedMessage defaultMessage="Generator treści" />
        </Title>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <TextAreaForm />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <ExampleTexts />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Generator;
