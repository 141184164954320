import { FormattedMessage } from 'react-intl';

export const listItems = [
  {
    title: (
      <FormattedMessage defaultMessage="#1 E-comerce - skuteczny opis sprzedażowy odzieży" />
    ),
    description: (
      <FormattedMessage defaultMessage="Jestem właścicielem butiku odzieżowego. Mam w ofercie swojego butiku internetowego wyjątkową czerwoną długą suknię balową, która wykonana jest z najwyższej jakości bawełny. Suknia w całości uszyta jest w Polsce, nadaje szyku i pozwala się wyróżnić na każdym przyjęciu. Przygotuj proszę skuteczny opis marketingowy, który skłoni kobiety do jej zakupu. Cena tej sukienki to 299 zł." />
    ),
  },
  {
    title: (
      <FormattedMessage defaultMessage="#2 E-commerce - wpis blogowy o naprawie telefonów" />
    ),
    description: (
      <FormattedMessage defaultMessage="Jestem specjalistą zajmującym się naprawą telefonów komórkowych. Chciałbym przygotować wpis na bloga, który będzie opisywał w jaki sposób można w warunkach domowych wymienić zbity wyświetlacz w smartfonie Asus Zenfone 9. Artykuł powinien informować użytkownika, że niezbędne części do naprawy (ekran, taśmę) oraz narzędzia (klej, zestaw śrubokrętów i ślizgi do zdejmowania obudowy) można zakupić w naszym sklepie internetowym. Warto, aby ostatni nagłówek artykułu wspomniał o tym, że jeżeli obawiasz się, że sobie nie poradzisz z samodzielną naprawą lub nie masz na nią czasu to możesz skorzystać z naszych usług świadczonych na odległość i zlecić nam naprawę." />
    ),
  },
  {
    title: (
      <FormattedMessage defaultMessage="#3 Blog profesjonalny - wpis blogowy mówiący o różnicach pomiędzy terapią manualną i masażem" />
    ),
    description: (
      <FormattedMessage defaultMessage="Jestem specjalistą fizjoterapii, który prowadzi własny gabinet. Chciałbym na moim blogu opublikować artykuł, który wytłumaczy czytelnikom i moim potencjalnym pacjentom jaka jest różnica pomiędzy zabiegami terapii manualnej, a masażu. W trakcie tworzenia artykułu zwróć uwagę na odmienne zalecenia zdrowotne, wymogi związane z wiedzą, doświadczeniem i prawem (według Twojej aktualnej wiedzy) oraz rodzaj schorzeń, które rehabilitowane są tymi dwoma metodami. Chciałbym, żeby taki artykuł jednocześnie zaznaczył, że oba te rodzaje usług są możliwe do realizacji w moim gabinecie" />
    ),
  },
  {
    title: (
      <FormattedMessage defaultMessage="#4 Blog osobisty - wpis blogowy na blogu parentingowym" />
    ),
    description: (
      <FormattedMessage defaultMessage="Jestem influencerem i prowadzę blog osobisty związany z rodzicielstwem. Chciałbym, abyś przygotował treść artykułu, który będzie prezentował 10 ciekawych zabaw stymulujących rozwój dziecka w wieku 2-3 lata, które nadają się idealnie do organizacji we własnym domu z wykorzystaniem popularnych produktów dostępnych w każdym domu." />
    ),
  },
];
