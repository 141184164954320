import { Skeleton } from 'antd';

export const getTableSkeleton = (keys: string[], rows: number): any => {
  return Array.from({ length: rows }, (_, idx) =>
    keys.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: <Skeleton data-testid="skeleton" active paragraph={false} />,
      }),
      { key: idx, hideActions: true },
    ),
  );
};
