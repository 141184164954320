import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ButtonStates } from 'views/Documents/hooks/useButtonState';
import styles from './styles.module.scss';

type Props = {
  buttonState: ButtonStates;
  isLoading: boolean;
  isDisabled?: boolean;
};

const ButtonWrapper: React.FC<Props> = ({
  buttonState,
  isLoading,
  isDisabled = false,
}) => {
  return (
    <div className={styles.buttonWrapper}>
      {buttonState === 'submitted' || buttonState === 'final' ? (
        <Button
          type="primary"
          htmlType="submit"
          style={{
            backgroundColor: '#6fbf77',
          }}
          loading={isLoading}
          disabled={isDisabled}
        >
          <FormattedMessage defaultMessage="ZAMÓWIENIE (z obowiązkiem zapłaty)" />
        </Button>
      ) : (
        <Button
          type="primary"
          htmlType="submit"
          disabled={buttonState === 'clean'}
        >
          <FormattedMessage defaultMessage="Przygotuj wycenę (oferta bez obowiązku płatności)" />
        </Button>
      )}
    </div>
  );
};

export default ButtonWrapper;
