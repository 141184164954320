import { Button, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const DeleteAccount = () => {
  const [notify, contextHolder] = notification.useNotification();


  return (
    <>
      {contextHolder}
      <FormattedMessage defaultMessage="Aby usunąć konto kliknij w poniższy link i na adres e-mail przypisany do konta zostanie wysłana wiadomość potwierdzająca." />
      <Button
        type="primary"
        className="width-100 mt-25 mb-25"
      >
        <Link to="mailto:support@businesswriter.ai?subject=Usunięcie%20konta%20-%20wniosek&body=Wnioskuję%20o%20usunięcie%20konta%20w%20systemie%20BusinessWriter.ai%20i%20potwierdzam%2C%20że%20zdaję%20sobie%20sprawę%2C%20iż%20wiąże%20się%20to%20z%20utrata%20dostępu%20do%20konta%20oraz%20wygenerowanych%20wcześniej%20treści.%20Potwierdzam%20również%2C%20iż%20rozumiem%2C%20że%20wszelkie%20niewykorzystane%20środki%20pozostające%20na%20koncie%20zostaną%20bezpowrotnie%20utracone.%0A%0AWniosek%20dotyczy%20konta%20użytkownika%20założonego%20na%20adres%20e-mail%20z%20którego%20wysyłam%20tę%20wiadomość.">
          <FormattedMessage defaultMessage="Usuń konto" />
        </Link>
      </Button>
    </>
  );
};

export default DeleteAccount;
