import { Button, notification } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLazyResetUserPasswordQuery } from 'ducks/user/service';

type Props = {
  isSocial: boolean;
};

const ResetPassword: React.FC<Props> = ({ isSocial }) => {
  const [notify, contextHolder] = notification.useNotification();

  const [resetPassword, { isFetching, isSuccess, isError }] =
    useLazyResetUserPasswordQuery();

  useEffect(() => {
    if (isFetching) return;

    if (isSuccess) {
      notify.success({
        message: <FormattedMessage defaultMessage="Email został wysłany." />,
        description: (
          <FormattedMessage defaultMessage="Sprawdź skrzynkę pocztową i postępuj zgodnie z instrukcjami zawartymi w e-mailu." />
        ),
      });
    }

    if (isError) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Email nie został wysłany." />
        ),
        description: (
          <FormattedMessage defaultMessage="Coś poszło nie tak, spróbuj ponownie później. Jeżeli uważasz że to błąd, zgłoś nam ten fakt." />
        ),
      });
    }
  }, [isError, isFetching, isSuccess, notify]);

  if (isSocial) {
    return (
      <FormattedMessage defaultMessage="Logowałeś się do konta za pomocą konta społecznościowego. Jeżeli chcesz zmienić hasło to musisz zmienić je bezpośrednio w Twoim koncie społecznościowym." />
    );
  }

  return (
    <>
      {contextHolder}
      <FormattedMessage defaultMessage="Aby zresetować hasło kliknij na poniższy przycisk. Zostanie do Ciebie wysłany e-mail z linkiem do zresetowania hasła." />
      <Button
        type="primary"
        className="width-100 mt-25 mb-25"
        loading={isFetching}
        onClick={() => resetPassword(undefined, false)}
      >
        <FormattedMessage defaultMessage="Zresetuj hasło" />
      </Button>
    </>
  );
};

export default ResetPassword;
