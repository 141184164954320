import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import {
  User,
  UserTag,
  UserStatistics,
  SetUserRequest,
  SetUserResponse,
  ResetUserPasswordResponse,
} from './types';

export enum Endpoint {
  GetCurrentUser = '/user',
  UpdateUser = '/user',
  ResetUserPassword = '/user/change-password',
  GetCurrentUserStatistics = '/statistics',
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQuery(),
  tagTypes: [UserTag.User],
  endpoints: (build) => ({
    getCurrentUser: build.query<User, void>({
      query: () => ({
        url: Endpoint.GetCurrentUser,
        method: 'GET',
      }),
      providesTags: [UserTag.User],
    }),
    getCurrentUserStatistics: build.query<UserStatistics, void>({
      query: () => ({
        url: Endpoint.GetCurrentUserStatistics,
        method: 'GET',
      }),
      providesTags: [UserTag.User],
    }),
    updateUser: build.mutation<SetUserResponse, SetUserRequest>({
      query: (user) => ({
        url: Endpoint.UpdateUser,
        method: 'PATCH',
        data: user,
      }),
      invalidatesTags: [UserTag.User],
    }),
    resetUserPassword: build.query<ResetUserPasswordResponse, void>({
      query: () => ({
        url: Endpoint.ResetUserPassword,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useGetCurrentUserStatisticsQuery,
  useLazyResetUserPasswordQuery,
  useUpdateUserMutation,
} = userApi;
