import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import cn from 'classnames';
import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

type Props = {
  text?: string | ReactElement;
  children?: ReactElement;
  visible?: boolean;
  isAbsolute?: boolean;
  style?: React.CSSProperties;
};

const Loader: React.FC<Props> = ({
  text,
  children,
  visible = false,
  isAbsolute = false,
  style,
}) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  return (
    <div
      className={cn(styles.wrapper, { [styles.absolute]: isAbsolute })}
      style={style}
    >
      <Spin
        indicator={antIcon}
        tip={text}
        spinning={visible}
        data-testid="loader-spin"
      >
        {children}
      </Spin>
    </div>
  );
};

export default Loader;
