/* eslint-disable react/jsx-props-no-spreading */
import type { FormListData, FormListItemRecord } from '../../config';
import FormListItem from './components/FormListItem';
import style from './style.module.scss';

interface Props<TData> {
  data: FormListData<TData>;
  items: FormListItemRecord<TData>[];
  isLoading: boolean;
}

const FormListRow = <TData,>({ data, items, isLoading }: Props<TData>) => {
  return (
    <tr>
      {items.map(({ key, ...restItemProps }) => (
        <td key={key} className={style.tableCell}>
          <FormListItem
            key={key}
            data={data}
            isLoading={isLoading}
            {...restItemProps}
          />
        </td>
      ))}
    </tr>
  );
};

export default FormListRow;
