import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useBatchDocumentMutation,
  useCalculateDocumentMutation,
  useGetDocumentTemplatesQuery,
} from 'ducks/document/service';
import { getCopywriterPrice } from 'utilities/utilities';
import useButtonState from 'views/Documents/hooks/useButtonState';
import { ListItem } from './config';

const useManageCreator = () => {
  // api

  const [calculateDocument, { isLoading, data: calculateResponse }] =
    useCalculateDocumentMutation();

  const [
    batchDocument,
    {
      isLoading: batchDocumentLoading,
      isSuccess: batchDocumentSuccess,
      isError: batchDocumentError,
      error: batchDocumentErrorData,
    },
  ] = useBatchDocumentMutation();

  const { data: templatesData = [], isLoading: templatesLoading } =
    useGetDocumentTemplatesQuery();

  // helpers
  const generateDefaultItemObject = useCallback(
    (id = _.uniqueId()) => ({
      id,
      characters: 1500,
      phrases: null,
      headers: null,
      template_id: templatesLoading ? undefined : 1,
      lang: 'pl',
      isSynonimized: false,
      name: undefined,
    }),
    [templatesLoading],
  );

  // state
  const { buttonState, setButtonState, shouldRenderPrice } = useButtonState();
  const [listItems, setListItems] = useState<ListItem[]>([
    generateDefaultItemObject(),
  ]);

  useEffect(() => {
    if (templatesLoading) return;

    setListItems([generateDefaultItemObject()]);
  }, [templatesLoading, generateDefaultItemObject]);

  // functions

  const getSumOfCharacters = useCallback(
    () =>
      listItems.reduce((accumulator, currentValue) => {
        const currentCharacters = currentValue?.characters ?? 0;
        return accumulator + currentCharacters;
      }, 0),
    [listItems],
  );

  const setFilledState = ({
    allFields,
  }: {
    allFields: Record<number, ListItem>;
  }) => {
    const fieldsObjectsValues = Object.values(allFields);

    const filledObjects = fieldsObjectsValues.filter((fieldObject) => {
      const {
        characters,
        phrases,
        headers,
        template_id: templateId,
        lang,
        name,
      } = fieldObject;
      if (
        characters &&
        templateId &&
        lang &&
        name &&
        headers &&
        headers?.length > 0 &&
        phrases &&
        phrases?.length > 0
      )
        return true;
      return false;
    });

    const isValid = filledObjects.length === fieldsObjectsValues.length;

    if (isValid) setButtonState('filled');
    else setButtonState('clean');
  };

  const handleEdit = ({
    field,
    allFields,
  }: {
    field: Record<number, ListItem>;
    allFields: Record<number, ListItem>;
  }) => {
    setFilledState({ allFields });

    const id = Object.keys(field)[0];
    const newValues = Object.values(field)[0];

    setListItems((prev) =>
      prev.map((item) => (item?.id === id ? { ...item, ...newValues } : item)),
    );
  };

  const handleFormSubmit = async () => {
    if (buttonState === 'filled') {
      const sumOfCharacters = getSumOfCharacters();
      setButtonState('submitted');
      calculateDocument({ characters: sumOfCharacters });
    } else if (shouldRenderPrice) {
      setButtonState('final');
      const items = listItems.map(
        ({ template_id: templateId, id, ...rest }) => ({
          ...rest,
          template_id: templateId,
        }),
      );

      const sendData = {
        items,
      };

      batchDocument(sendData);
    }
  };

  const addNewItem = () => {
    setListItems((prevState) => [...prevState, generateDefaultItemObject()]);
  };

  const deleteItem = (id: string) => {
    if (listItems.length <= 1) return;
    setListItems(listItems.filter((item) => item.id !== id));
  };

  // memoized state

  const copywriterPrice = useMemo(() => {
    const price = calculateResponse?.price ?? 1;
    return getCopywriterPrice({ characters: getSumOfCharacters(), price });
  }, [calculateResponse, getSumOfCharacters]);

  const balance = useMemo(
    () =>
      calculateResponse
        ? calculateResponse.balance - calculateResponse.price
        : null,
    [calculateResponse],
  );

  const templatesOptions = useMemo(() => {
    return templatesData?.map((template) => ({
      value: template.id,
      label: template.name,
    }));
  }, [templatesData]);

  return {
    handleEdit,
    shouldRenderPrice,
    balance,
    copywriterPrice,
    handleFormSubmit,
    buttonState,
    listItems,
    batchDocumentLoading,
    batchDocumentSuccess,
    batchDocumentError,
    batchDocumentErrorData,
    isLoading,
    calculateResponse,
    addNewItem,
    deleteItem,
    templatesOptions,
    templatesLoading,
  };
};

export default useManageCreator;
