import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import {
  BankItem,
  CreatePaymentBody,
  CreatePaymentResponse,
  GetPaymentsArgs,
  OperationType,
  Payment,
  PaymentDetails,
  PaymentTag,
  PaymentWithDetails,
  RereatePaymentBody,
  SendDiscountCodeRequest,
} from './types';

enum Endpoint {
  GetPayments = '/charge',
  GetPaymentInfo = '/charge/:chargeId',
  CreatePayment = '/charge/createPayment',
  RecreatePayment = '/charge/recreatePayment/:chargeId',
  GetPaymentMethods = '/charge/paymentMethods',
  SendDiscountCode = '/discount-code',
}

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: baseQuery(),
  tagTypes: [PaymentTag.List],
  endpoints: (build) => ({
    getPayments: build.query<PaymentWithDetails[], GetPaymentsArgs | void>({
      query: () => ({
        url: Endpoint.GetPayments,
        method: 'GET',
      }),
      transformResponse: (response: Payment[], meta, args) => {
        if (!response) return [];

        // map data
        let mappedResponse = response.map((payment) => {
          const details: PaymentDetails = {};

          if (payment.type === OperationType.OrderDocument) {
            details.documentId = `#${payment.document.id}`;
          } else if (payment.type === OperationType.Payment) {
            details.paymentHash = payment.payment.transactionId;
          } else {
            details.discountCode = payment.code?.code;
          }

          return {
            ...payment,
            details,
          };
        });

        // order data
        if (args?.order === 'desc') {
          mappedResponse = mappedResponse.sort((a, b) => b.id - a.id);
        }

        // limit data
        if (args?.limit && mappedResponse.length > args.limit) {
          mappedResponse = mappedResponse.slice(0, args.limit);
        }

        return mappedResponse;
      },
      providesTags: [PaymentTag.List],
    }),
    getPayment: build.query<Payment, number>({
      query: (chargeId) => ({
        url: Endpoint.GetPaymentInfo.replace(':chargeId', `${chargeId}`),
        method: 'GET',
      }),
      providesTags: [PaymentTag.List],
    }),
    getPaymentMethods: build.query<BankItem[], void>({
      query: () => ({
        url: Endpoint.GetPaymentMethods,
        method: 'GET',
      }),
    }),
    createPayment: build.mutation<CreatePaymentResponse, CreatePaymentBody>({
      query: (data) => ({
        url: Endpoint.CreatePayment,
        method: 'POST',
        data,
      }),
      invalidatesTags: [PaymentTag.List],
    }),
    recreatePayment: build.mutation<CreatePaymentResponse, RereatePaymentBody>({
      query: ({ chargeId, ...data }) => ({
        url: Endpoint.RecreatePayment.replace(':chargeId', `${chargeId}`),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: [PaymentTag.List],
    }),
    sendDiscountCode: build.mutation<void, SendDiscountCodeRequest>({
      query: (data) => ({
        url: Endpoint.SendDiscountCode,
        method: 'POST',
        data,
      }),
      invalidatesTags: [PaymentTag.List],
    }),
  }),
});

export const {
  useGetPaymentsQuery,
  useLazyGetPaymentQuery,
  useGetPaymentMethodsQuery,
  useCreatePaymentMutation,
  useRecreatePaymentMutation,
  useSendDiscountCodeMutation,
} = paymentApi;
