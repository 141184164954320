import { ReactElement } from 'react';
import styles from './styles.module.scss';

type Props = {
  onClick: () => void;
  children?: ReactElement | ReactElement[];
};

const CtaButton: React.FC<Props> = ({ children, onClick }) => {
  return (
    <button type="button" onClick={onClick} className={styles.button}>
      {children}
    </button>
  );
};

export default CtaButton;
