import { NotificationInstance } from 'antd/es/notification/interface';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Outlet } from 'react-router-dom';
import { useGetCurrentUserQuery } from 'ducks/user/service';
import { Routes } from './Routing';

type Props = {
  notify: NotificationInstance;
};

const ProtectedRoutes: React.FC<Props> = ({ notify }) => {
  const { data, isLoading } = useGetCurrentUserQuery();
  const isUncompleted = !isLoading && data?.isCompleted === false;

  useEffect(() => {
    if (isUncompleted) {
      notify.info({
        message: <FormattedMessage defaultMessage="Uzupełnij swój profil." />,
        description: (
          <FormattedMessage defaultMessage="Aby móc w pełni korzystać z aplikacji przejdź do zakładki 'Twoje dane' i uzupełnij swoje dane." />
        ),
      });
    }
  }, [isUncompleted]);

  return data && data.isCompleted === true ? (
    <Outlet />
  ) : (
    <Navigate replace to={Routes.PROFILE} />
  );
};

export default ProtectedRoutes;
