import { BsFillPiggyBankFill } from 'react-icons/bs';
import { FaPenFancy, FaWallet } from 'react-icons/fa';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Routes } from 'components/main/App/Routing';
import { UserStatistics } from 'ducks/user/types';

export const getInfoBoxData = ({ account, written, saved }: UserStatistics) => [
  {
    title: <FormattedMessage defaultMessage="Stan Twojego konta" />,
    mainValue: (
      <FormattedNumber
        value={account.mainValue}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency="PLN"
      />
    ),
    secondValue: (
      <FormattedMessage
        defaultMessage="{signs} znaków"
        values={{ signs: <FormattedNumber value={account.secondValue || 0} /> }}
      />
    ),
    iconLink: Routes.PAYMENTS,
    icon: <FaWallet />,
    isSuccess: !!account.secondValue,
  },
  {
    title: <FormattedMessage defaultMessage="Napisaliśmy dla Ciebie" />,
    mainValue: (
      <FormattedMessage
        defaultMessage="{value} tekstów"
        values={{ value: written.mainValue }}
      />
    ),
    secondValue: (
      <FormattedMessage
        defaultMessage="{value} znaków"
        values={{ value: <FormattedNumber value={written.secondValue || 0} /> }}
      />
    ),
    iconLink: Routes.DOCUMENTS,
    icon: <FaPenFancy />,
    isSuccess: written.secondValue ? true : undefined,
  },
  {
    title: <FormattedMessage defaultMessage="Zaoszczędziłeś z nami około" />,
    mainValue: (
      <FormattedNumber
        value={saved.mainValue}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency="PLN"
      />
    ),
    secondValue: (
      <a
        href="https://businesswriter.ai/oszczedzaj-na-copywritingu/"
        target="_blank"
        rel="noreferrer"
      >
        <FormattedMessage defaultMessage="Jak to liczymy?" />
      </a>
    ),
    iconLink: Routes.PAYMENTS,
    icon: <BsFillPiggyBankFill />,
  },
  /* {
    title: (
      <FormattedMessage defaultMessage="W programie partnerskim zarobiłeś" />
    ),
    mainValue: (
      <FormattedNumber
        value={affiliate.mainValue}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency="PLN"
      />
    ),
    secondValue: (
      <FormattedMessage
        values={{
          count: affiliate.secondValue || 0,
        }}
        defaultMessage="{count, plural, one {# polecony} other {# poleconych}}"
      />
    ),
    icon: <FaMoneyBillWave />,
    isSuccess: affiliate.secondValue ? true : undefined,
  }, */
];
