import { Button, Input, Space, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { AiFillCopy } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

import styles from './styles.module.scss';

type Props = {
  value: string;
};

const SecretField: React.FC<Props> = ({ value }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [copiedValue, copyToClipboard] = useCopyToClipboard();

  const handleCopyClick = async () => {
    await copyToClipboard(value);
    setTooltipVisible(true);
  };

  useEffect(() => {
    if (tooltipVisible === true)
      setTimeout(() => setTooltipVisible(false), 2000);
  }, [tooltipVisible]);

  return (
    <Space direction="horizontal" className={styles.fullWidth} align="center">
      <Input.Password
        readOnly
        bordered={false}
        value={value}
        className={styles.fullWidth}
      />
      <Tooltip
        title={<FormattedMessage defaultMessage="Skopiowano" />}
        open={tooltipVisible}
      >
        <Button
          type="link"
          className={styles.btn}
          icon={<AiFillCopy size={15} />}
          onClick={() => handleCopyClick()}
        />
      </Tooltip>
    </Space>
  );
};

export default SecretField;
