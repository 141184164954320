import { InvoiceRow } from 'ducks/invoice/types';

export enum PaymentTag {
  List = 'List',
}

export enum OperationType {
  Payment = 1,
  OrderDocument = 2,
  Bonus = 3,
}

export enum PaymentStatus {
  Pending = 1,
  Paid = 2,
  Correct = 3,
  Refund = 4,
  Error = 5,
}

export type GetPaymentsArgs = {
  limit?: number;
  order?: 'asc' | 'desc';
};

export type PaymentRow = {
  amount: number;
  chargeId: number;
  createdAt: string;
  groupId: number;
  id: number;
  status: PaymentStatus;
  transactionId: string;
};

export type DocumentRow = {
  id: number;
  name: string;
  chargeId: number;
  saved: number;
};

export type DiscountCodeRow = {
  id: number;
  code: string;
  amount: number;
  laravel_through_key: number;
};

export type Payment = {
  id: number;
  code: DiscountCodeRow;
  type: OperationType;
  amount: number;
  createdAt: string;
  document: DocumentRow;
  invoice: InvoiceRow | null;
  payment: PaymentRow;
};

export type BankItem = {
  name: string;
  img: string;
  id: string;
};

export type PaymentDetails = Record<string, string | number>;

export type PaymentWithDetails = Payment & {
  details: PaymentDetails;
};

export type CreatePaymentBody = {
  amount: number;
  groupId: number;
};

export type RereatePaymentBody = CreatePaymentBody & {
  chargeId: number;
};

export type CreatePaymentResponse = {
  charge: Payment;
  url: string;
};

export type SendDiscountCodeRequest = {
  code: string;
};
