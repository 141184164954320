import type { FormListData, FormListItemRecord } from './config';
import type { FormInstance } from 'antd';
import type { ReactElement } from 'react';
import { Empty, Space, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { createArray } from 'utilities/array';
import FormListRow from './components/FormListRow';
import styles from './styles.module.scss';

interface Props<TData> {
  form: FormInstance<TData>;
  data: FormListData<TData>[];
  isLoading: boolean;
  items: FormListItemRecord<TData>[];
  title?: ReactElement | string;
}

const { Text } = Typography;

const FormList = <TData,>({
  form,
  data,
  isLoading,
  items,
  title,
}: Props<TData>) => {
  const isEmpty = !data.length && !isLoading;
  const dataSource = isLoading
    ? (createArray(2).map((number) => ({
        id: number,
      })) as FormListData<TData>[])
    : data;

  return (
    <Space direction="vertical" size="middle" className="width-100">
      {title && <Text strong>{title}</Text>}
      {isEmpty ? (
        <Empty
          description={<FormattedMessage defaultMessage="Brak danych" />}
        />
      ) : (
        <Space direction="vertical" size="large" className={styles.formSpace}>
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr>
                {items.map(({ key, title: itemTitle }) => (
                  <th key={key} scope="col">
                    <Text
                      strong
                      type="secondary"
                      ellipsis
                      className={styles.colTitle}
                    >
                      {itemTitle}
                    </Text>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataSource.map((item) => {
                const { id } = item;
                form.setFieldValue(item.id, item);

                return (
                  <FormListRow
                    key={id}
                    data={item}
                    items={items}
                    isLoading={isLoading}
                  />
                );
              })}
            </tbody>
          </table>
        </Space>
      )}
    </Space>
  );
};

export default FormList;
