import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery, { doRequest } from 'ducks/api';
import {
  InvoiceTag,
  AllInvoicesResponse,
  DownloadInvoiceResponse,
} from './types';

enum Endpoint {
  GetAllInvoiceData = '/invoice',
  DownloadInvoice = '/invoice/download/:invoiceId',
}

export const invoiceApi = createApi({
  reducerPath: 'invoiceApi',
  baseQuery: baseQuery(),
  tagTypes: [InvoiceTag.Invoice],
  endpoints: (build) => ({
    getAllInvoices: build.query<AllInvoicesResponse, void>({
      query: () => ({
        url: Endpoint.GetAllInvoiceData,
        method: 'GET',
      }),
      providesTags: [InvoiceTag.Invoice],
    }),
  }),
});

export const getInvoicePdf = (
  invoiceId: number,
): Promise<DownloadInvoiceResponse> =>
  doRequest({
    url: Endpoint.DownloadInvoice.replace(':invoiceId', `${invoiceId}`),
    responseType: 'blob',
  });

export const { useGetAllInvoicesQuery } = invoiceApi;
