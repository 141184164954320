export const terms = `
<p> 
Chcemy Cię poinformować, że na naszych stronach internetowych znajdziesz:
</p>
<ul>
    <li>
    <a href="https://businesswriter.ai/regulamin/" target="_blank">
        Regulamin,
    </a>
    </li>
    <li>
    <a href="https://businesswriter.ai/polityka-prywatnosci/" target="_blank">
        Politykę Prywatności,
    </a>
    </li>
    <li>
    <a href="https://businesswriter.ai/zgoda-na-przetwarzanie-danych-osobowych/" target="_blank">
        Zasady Przetwarzania Danych Osobowych.
    </a>
    </li>
</ul>

<p>
Twoje dane są u nas bezpieczne, nie przekazujemy ich nikomu poza realizacją naszych usług i na pewno ich nie sprzedajemy! Nie zalewamy Cię też spamem. Jeśli kiedykolwiek będziesz chciał zrezygnować z otrzymywania naszych wiadomości marketingowych lub usunąć swoje konto, możesz to zrobić w dowolnym momencie.
</p>
<p>
Akceptując warunki serwisu zgadzasz się z treścią powyższych dokumentów. Zgoda na komunikację marketingową jest natomiast dobrowolna.
</p>
`;
