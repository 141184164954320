import {
  Button,
  Card,
  Col,
  List,
  Row,
  Skeleton,
  Space,
  Typography,
  notification,
} from 'antd';
import cn from 'classnames';
import { useState, useEffect } from 'react';
import { FaRegCopy, FaSave } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Loader from 'components/ui/atoms/Loader/Loader';
import Editor from 'components/ui/organisms/Editor';
import {
  useGetDocumentQuery,
  useUpdateDocumentMutation,
} from 'ducks/document/service';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useViewport from 'hooks/useViewport';
import { getDocumentParams } from './utils';

const { Title } = Typography;
const { Item } = List;
const { Meta } = Item;

const MOBILE_WIDTH = 375;

const Edit: React.FC = () => {
  const [updatedText, setUpdatedText] = useState('');
  const { documentId = 0 } = useParams();
  const { width } = useViewport();
  const [copiedValue, copyToClipboard] = useCopyToClipboard();
  const [notify, contextHolder] = notification.useNotification();

  const { data, isFetching } = useGetDocumentQuery(+documentId, {
    skip: !documentId,
  });

  const [updateDocument, { isLoading: isUpdating, isSuccess, isError }] =
    useUpdateDocumentMutation();

  useEffect(() => {
    if (data) setUpdatedText(data.content);
  }, [data]);

  useEffect(() => {
    if (isUpdating) return;

    if (isError) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas zapisu." />
        ),
        description: (
          <FormattedMessage defaultMessage="Podczas zapisu danych wystąpił błąd, spróbuj ponownie. Jeżeli uważasz że to błąd, zgłoś zaistniałą sytuację w BOK" />
        ),
      });
    }

    if (isSuccess) {
      notify.success({
        message: <FormattedMessage defaultMessage="Dane zapisano." />,
        description: (
          <FormattedMessage defaultMessage="Dokument został zaktualizowany." />
        ),
      });
    }
  }, [isUpdating, isSuccess, isError, notify]);

  const handleTextChange = (value: string) => {
    setUpdatedText(value);
  };

  const handleCopyText = () => {
    copyToClipboard(updatedText);
    notify.info({
      message: <FormattedMessage defaultMessage="Skopiowano." />,
      description: (
        <FormattedMessage defaultMessage="Twój tekst został skopiowany do schowka." />
      ),
    });
  };

  const handleSaveText = () => {
    updateDocument({
      documentId: +documentId,
      name: data?.name || '',
      content: updatedText,
    });
  };

  return (
    <div className="layout-content mb-24">
      {contextHolder}
      <Card bordered={false} className="criclebox cardbody mb-24">
        <div className="project-ant mb-4">
          <Title level={5}>
            <FormattedMessage defaultMessage="Parametry dokumentu" />
          </Title>
        </div>
        <Skeleton active={true} loading={isFetching}>
          <List
            itemLayout="horizontal"
            dataSource={getDocumentParams(data)}
            renderItem={({ title, description }) => (
              <Item>
                <Meta title={title} description={description} />
              </Item>
            )}
          />
        </Skeleton>
      </Card>

      <Card bordered={false}>
        <Loader
          text={
            <FormattedMessage defaultMessage="Trwa generowanie dokumentu" />
          }
          visible={data?.status === 1}
        >
          <>
            <div className="project-ant mb-4">
              <Row
                justify="space-between"
                align="middle"
                className="ant-form-item-row"
                gutter={[24, 12]}
              >
                <Col>
                  <Title level={5}>
                    <FormattedMessage defaultMessage="Dokument" />
                  </Title>
                </Col>
                <Col flex={width < MOBILE_WIDTH ? 1 : undefined}>
                  <Space
                    direction={width < MOBILE_WIDTH ? 'vertical' : 'horizontal'}
                    className={cn({ 'width-100': width < MOBILE_WIDTH })}
                  >
                    <Button
                      icon={<FaRegCopy />}
                      type="primary"
                      className="width-100"
                      onClick={handleCopyText}
                      disabled={isFetching || isUpdating}
                    >
                      <FormattedMessage defaultMessage="Skopiuj tekst" />
                    </Button>
                    <Button
                      icon={<FaSave />}
                      type="primary"
                      className="width-100"
                      onClick={handleSaveText}
                      disabled={isFetching}
                      loading={isUpdating}
                    >
                      <FormattedMessage defaultMessage="Zapisz zmiany" />
                    </Button>
                  </Space>
                </Col>
              </Row>
            </div>
            <Skeleton active={true} loading={isFetching}>
              <Editor value={updatedText} onChange={handleTextChange} />
            </Skeleton>
          </>
        </Loader>
      </Card>
    </div>
  );
};

export default Edit;
