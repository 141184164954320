import { FormattedDate, FormattedTime } from 'react-intl';

type Props = {
  date: Date | string;
};

const FormatDate: React.FC<Props> = ({ date }) => {
  const dateObj = date instanceof Date ? date : new Date(date);
  return (
    <>
      <FormattedDate
        value={dateObj}
        year="numeric"
        month="long"
        day="2-digit"
      />
      {', '}
      <FormattedTime value={dateObj} />
    </>
  );
};

export default FormatDate;
