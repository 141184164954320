import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'ducks/api';
import { DefaultResponse } from 'ducks/types';
import {
  CreateLicenseRequest,
  License,
  LicenseTag,
  UpdateLicenseRequest,
} from './types';

export enum Endpoint {
  GetLicenses = '/license',
  CreateLicenses = '/license',
  DeleteLicense = '/license/:licenseId',
  UpdateLicense = '/license/:licenseId',
}

export const licenseApi = createApi({
  reducerPath: 'licenseApi',
  baseQuery: baseQuery(),
  tagTypes: [LicenseTag.List],
  endpoints: (build) => ({
    getLicenses: build.query<License[], void>({
      query: () => ({
        url: Endpoint.GetLicenses,
        method: 'GET',
      }),
      providesTags: [LicenseTag.List],
    }),
    createLicense: build.mutation<DefaultResponse, CreateLicenseRequest>({
      query: (data) => ({
        url: Endpoint.CreateLicenses,
        method: 'POST',
        data,
      }),
      invalidatesTags: [LicenseTag.List],
    }),
    updateLicense: build.mutation<DefaultResponse, UpdateLicenseRequest>({
      query: ({ name, id }) => ({
        url: Endpoint.UpdateLicense.replace(':licenseId', id),
        method: 'PATCH',
        data: { name },
      }),
      invalidatesTags: [LicenseTag.List],
    }),
    deleteLicense: build.mutation<DefaultResponse, number>({
      query: (id: number) => ({
        url: Endpoint.DeleteLicense.replace(':licenseId', `${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: [LicenseTag.List],
    }),
  }),
});

export const {
  useGetLicensesQuery,
  useDeleteLicenseMutation,
  useCreateLicenseMutation,
  useUpdateLicenseMutation,
} = licenseApi;
