export enum InvoiceTag {
  Invoice = 'Invoice',
}

export enum InvoiceStatus {
  InProgress = 1,
  Ready = 2,
}

export type InvoiceRow = {
  id: number;
  number: string;
  status: InvoiceStatus;
  createdAt: string;
};

export type AllInvoicesResponse = InvoiceRow[];

export type DownloadInvoiceResponse = any;
