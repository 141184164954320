import { Menu, Button } from 'antd';
import cn from 'classnames';
import { useState } from 'react';
import { FaKey, FaMoneyBillWaveAlt, FaUserCircle } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { ImHome3, ImBullhorn } from 'react-icons/im';
import { IoDocument, IoLogOut } from 'react-icons/io5';
import { useIntl, IntlShape, FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { Routes } from 'components/main/App/Routing';
import { LogoutButton } from 'components/main/AuthProvider';
import PaymentModal from 'components/ui/organisms/PaymentModal';
import styles from './styles.module.scss';

export const getMenu = (intl: IntlShape) => [
  {
    label: intl.formatMessage({ defaultMessage: 'Kokpit' }),
    icon: <ImHome3 />,
    url: Routes.DASHBOARD,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Płatności i faktury' }),
    icon: <FaMoneyBillWaveAlt />,
    url: Routes.PAYMENTS,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Twoje treści' }),
    icon: <IoDocument />,
    url: Routes.DOCUMENTS,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Twoje licencje' }),
    icon: <FaKey />,
    url: Routes.LICENSES,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Ustawienia' }),
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Twoje dane' }),
    icon: <FaUserCircle />,
    url: Routes.PROFILE,
  },
  {
    label: intl.formatMessage({ defaultMessage: 'Zasil konto' }),
    icon: <GiReceiveMoney className={styles.paymentsIcon} />,
  },
];

const Sidenav: React.FC = () => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const intl = useIntl();
  const menu = getMenu(intl);

  return (
    <>
      <PaymentModal
        initialAmount={0}
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
      />
      <div className="brand">
        <Logo />
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {menu.map(({ label, icon, url }) => {
          if (!url) {
            if (icon)
              // this case is (at least for now) only for payment
              return (
                <Menu.Item
                  key={label}
                  className={styles.paymentsMenuItem}
                  onClick={() => setIsPaymentModalOpen(true)}
                >
                  <div className={styles.paymentsButton}>
                    <span className="icon">{icon}</span>
                    <span className="label">{label}</span>
                  </div>
                </Menu.Item>
              );

            return (
              <Menu.Item className="menu-item-header" key={label}>
                {label}
              </Menu.Item>
            );
          }

          return (
            <Menu.Item key={url}>
              <NavLink to={url}>
                <span className="icon">{icon}</span>
                <span className="label">{label}</span>
              </NavLink>
            </Menu.Item>
          );
        })}
        <Menu.Item key="logout">
          <LogoutButton>
            <span className="icon">
              <IoLogOut />
            </span>
            <span className="label">
              <FormattedMessage defaultMessage="Wyloguj się" />
            </span>
          </LogoutButton>
        </Menu.Item>
      </Menu>
      <div className="aside-footer">
        <div className={cn('footer-box', styles.footerBox)}>
          <span className={cn('icon', styles.icon)}>
            <ImBullhorn />
          </span>
          <h6>
            <FormattedMessage defaultMessage="Bądź na bieżąco!" />
          </h6>
          <p>
            <FormattedMessage defaultMessage="Wykorzystaj maksimum naszych możliwości!" />
          </p>
          <Button
            type="primary"
            className="ant-btn-block"
            onClick={() => {
              // onClick used to preserve button instead of a
              window.open('https://businesswriter.ai/blog/', '_blank');
            }}
          >
            <FormattedMessage defaultMessage="PRZEJDŹ" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default Sidenav;
