import { Form, Input, Checkbox } from 'antd';
import { useState, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetCurrentUserQuery } from 'ducks/user/service';
import { UserFormType } from 'ducks/user/types';
import FormCommon, { WrapperConfig } from '../FormCommon';

const { Item } = Form;

const getFieldLabel = (fieldName: string): ReactElement | string =>
  ({
    firstName: <FormattedMessage defaultMessage="Imię" />,
    lastName: <FormattedMessage defaultMessage="Nazwisko" />,
    email: <FormattedMessage defaultMessage="E-mail" />,
  }[fieldName] || '');

const FormBasic: React.FC = () => {
  const [wrapperConfig, setWrapperConfig] = useState<WrapperConfig>();

  const { data: user } = useGetCurrentUserQuery();

  return (
    <FormCommon
      getFieldLabel={getFieldLabel}
      onWrapperConfigChange={setWrapperConfig}
      type={UserFormType.Account}
    >
      <Item
        label={getFieldLabel('firstName')}
        name="firstName"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage defaultMessage="To pole jest obowiązkowe" />
            ),
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label={getFieldLabel('lastName')}
        name="lastName"
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage defaultMessage="To pole jest obowiązkowe" />
            ),
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label={getFieldLabel('email')}
        name="email"
        help={
          user?.isSocial ? (
            <FormattedMessage defaultMessage="Logowałeś się za pomocą konta społecznościowego. Nie ma możliwości edycji adresu email w takim przypadku." />
          ) : (
            ''
          )
        }
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage defaultMessage="To pole jest obowiązkowe" />
            ),
          },
          {
            type: 'email',
            message: (
              <FormattedMessage defaultMessage="Wprowadź poprawny adres e-mail" />
            ),
          },
        ]}
      >
        <Input disabled={user?.isSocial} />
      </Item>
      <Item
        name="agreeMarketing"
        valuePropName="checked"
        wrapperCol={wrapperConfig}
      >
        <Checkbox>
          <FormattedMessage defaultMessage="Zgoda marketingowa" />
        </Checkbox>
      </Item>
    </FormCommon>
  );
};

export default FormBasic;
