import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Form, Typography, notification } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'components/main/App/Routing';
import FormList from 'components/ui/molecules/FormList';
import ResponseBoxes from 'views/Documents/components/ResponseBoxes';
import { Routes as DocsRoutes } from 'views/Documents/Routing';
import ButtonWrapper from '../../components/ButtonWrapper/ButtonWrapper';
import { sharedFormListItems } from './config';
import styles from './styles.module.scss';
import useManageCreator from './useManageCreator';

const { Title } = Typography;
const { useForm } = Form;

const Creator: React.FC = () => {
  const [notify, contextHolder] = notification.useNotification();
  const [form] = useForm();
  const navigate = useNavigate();

  const {
    handleEdit,
    batchDocumentLoading,
    batchDocumentSuccess,
    batchDocumentError,
    batchDocumentErrorData,
    handleFormSubmit,
    listItems,
    buttonState,
    shouldRenderPrice,
    copywriterPrice,
    calculateResponse,
    isLoading,
    balance,
    addNewItem,
    deleteItem,
    templatesOptions,
    templatesLoading,
  } = useManageCreator();

  useEffect(() => {
    if (batchDocumentLoading) return;
    if (batchDocumentError) {
      notify.error({
        message: <FormattedMessage defaultMessage="Spróbuj ponownie" />,
      });
    }

    if (batchDocumentSuccess) {
      notify.success({
        message: (
          <FormattedMessage defaultMessage="Utworzono pomyślnie dokument" />
        ),
      });
      navigate(`${Routes.DOCUMENTS}${DocsRoutes.LIST}`);
    }
  }, [
    batchDocumentError,
    batchDocumentSuccess,
    batchDocumentLoading,
    notify,
    navigate,
  ]);

  return (
    <div className="layout-content mb-24">
      {contextHolder}
      <Card bordered={false}>
        <Form
          onFinish={handleFormSubmit}
          form={form}
          onValuesChange={(field, allFields) =>
            handleEdit({ allFields, field })
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        >
          <FormList
            title={
              <div className={styles.titleWrapper}>
                <Title level={5}>
                  <FormattedMessage defaultMessage="Kreator" />
                </Title>
                <Button
                  onClick={addNewItem}
                  type="link"
                  icon={<PlusCircleOutlined />}
                  disabled={templatesLoading}
                >
                  <FormattedMessage defaultMessage="Dodaj" />
                </Button>
              </div>
            }
            form={form}
            data={listItems}
            items={sharedFormListItems({
              handleDeleteRow: deleteItem,
              templatesOptions,
              templatesLoading,
            })}
            isLoading={false}
          />
          <ButtonWrapper
            isLoading={isLoading || batchDocumentLoading}
            buttonState={buttonState}
            isDisabled={!!balance && balance < 0}
          />
        </Form>
        <ResponseBoxes
          shouldRenderPrice={shouldRenderPrice}
          calculateIsLoading={isLoading}
          copywriterPrice={copywriterPrice}
          calculateData={calculateResponse}
          documentErrorData={batchDocumentErrorData}
          balance={balance}
        />
      </Card>
    </div>
  );
};

export default Creator;
