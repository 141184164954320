export enum UserTag {
  User = 'User',
}

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  postCode: string;
  company: string;
  nip: string;
  agreeMarketing: boolean;
  isCompany: boolean;
  isSocial: boolean;
  isCompleted: boolean;
  createdAt: string;
  updatedAt: string;
};

export type UserStatistics = Record<
  'account' | 'written' | 'saved' | 'affiliate',
  { mainValue: number; secondValue?: number }
>;

export enum UserFormType {
  Account = 'account',
  Invoice = 'invoice',
}

export type SetUserResponse = any;
export type ResetUserPasswordResponse = any;

export type SetUserRequest = Omit<
  Partial<User>,
  'createdAt' | 'updatedAt' | 'id'
> & {
  type: UserFormType;
};
