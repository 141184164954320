import { ResultStatusType } from 'antd/es/result';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Payment, PaymentStatus } from 'ducks/payment/types';

type PaymentStatusSlim = Omit<
  PaymentStatus,
  PaymentStatus.Correct | PaymentStatus.Refund
>;

export const getPaymentStatus = (
  params: Record<string, string | undefined> | undefined,
  paymentData: Payment | undefined,
  isPaymentFetching: boolean,
): PaymentStatus => {
  // ERROR: when return url error or payment status error
  if (
    (params && params['*'] === 'error') ||
    paymentData?.payment.status === PaymentStatus.Error
  )
    return PaymentStatus.Error;

  // PENDING: when payment status is pending or payment data are fetching
  if (
    isPaymentFetching ||
    paymentData?.payment.status === PaymentStatus.Pending
  )
    return PaymentStatus.Pending;

  // PAID: all other cases
  return PaymentStatus.Paid;
};

export const mapPaymentStatusToLabel = (
  paymentStatus: PaymentStatusSlim,
): ReactElement | string =>
  ({
    [PaymentStatus.Error]: (
      <FormattedMessage defaultMessage="Twoja płatność nie powiodła się" />
    ),
    [PaymentStatus.Pending]: (
      <FormattedMessage defaultMessage="Twoja płatność jest właśnie weryfikowana" />
    ),
    [PaymentStatus.Paid]: (
      <FormattedMessage defaultMessage="Twoja płatność została zaksięgowana" />
    ),
  }[`${paymentStatus}`] || '');

export const mapPaymentStatusToResultStatus = (
  paymentStatus: PaymentStatusSlim,
): ResultStatusType =>
  ({
    [PaymentStatus.Error]: 'error',
    [PaymentStatus.Paid]: 'success',
  }[`${paymentStatus}`] as ResultStatusType);
