import { Payment } from 'ducks/payment/types';

export enum DocumentTag {
  Document = 'Document',
}

export type License = {
  id: number;
  name: string;
};

export enum DocumentStatus {
  InProgress = 1,
  Done = 2,
  Flagged = 3,
  Canceled = 4,
}

export type GetDocumentsArgs = {
  limit?: number;
  order?: 'asc' | 'desc';
};

export enum DocumentFlags {
  HATE = 'hate',
  HATE_THREATING = 'hate/threatening',
  HARASSMENT = 'harassment',
  HARASSMENT_THREATING = 'harassment/threatening',
  SELF_HARM = 'self-harm',
  SELF_HARM_INTENT = 'self-harm/intent',
  SELF_HARM_INSTRUCTIONS = 'self-harm/instructions',
  SEXUAL = 'sexual',
  SEXUAL_MINORS = 'sexual/minors',
  VIOLENCE = 'violence',
  VIOLENCE_GRAPHICS = 'violence/graphic',
}

export type Document = {
  id: number;
  chargeId: number;
  name: string;
  keywords: string[] | null;
  phrases: string[] | null;
  headers: string[] | null;
  query: string | null;
  content: string;
  characters: number;
  status: DocumentStatus;
  createdAt: string;
  license?: License;
  charge?: Payment;
  saved: number;
  lang: string;
  template_id: number;
  isSynonimized: boolean;
  flags?: DocumentFlags[];
};

export type UpdateDocumentResponse = any;
export type UpdateDocumentRequest = {
  documentId: number;
  name: string;
  content: string;
};

export type CalculateDocumentResponse = {
  price: number;
  balance: number;
};

export type CalculateDocumentBody = {
  characters: number;
};

export type BatchDocumentRequest = { items: Partial<Omit<Document, 'id'>>[] };

export type CreateDocumentRequest = Partial<Document>;

export type GetDocumentTemplatesResponse = {
  name: string;
  id: string;
}[];
