/* eslint-disable react/jsx-props-no-spreading */
import { Col, Row, Skeleton, Typography } from 'antd';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { BsFillPiggyBankFill } from 'react-icons/bs';
import { CiDiscount1 } from 'react-icons/ci';
import { FaWallet } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import CtaButton from 'components/ui/atoms/CtaButton';
import InfoBox from 'components/ui/molecules/InfoBox';
import Table from 'components/ui/molecules/Table';
import PaymentModal from 'components/ui/organisms/PaymentModal';
import { getInvoicePdf } from 'ducks/invoice/service';
import {
  useGetPaymentsQuery,
  useRecreatePaymentMutation,
} from 'ducks/payment/service';
import { useGetCurrentUserStatisticsQuery } from 'ducks/user/service';
import DiscountCodeModal from './components/DiscountCodeModal';
import PaymentStatusModal from './components/PaymentStatusModal';
import { columns, PaymentRow } from './config';

const { Title } = Typography;
const colProps = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 6,
  className: 'mb-24',
};

const Payments: React.FC = () => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [paymentsList, setPaymentsList] = useState<PaymentRow[]>([]);
  const [search] = useSearchParams();
  const intl = useIntl();

  const { data: userData, isFetching: isUserFetching } =
    useGetCurrentUserStatisticsQuery();

  const { data: paymentsData, isFetching: isPaymentsFetching } =
    useGetPaymentsQuery({
      order: 'desc',
    });

  const handleCloseDiscountCodeModal = useCallback(() => {
    // eslint-disable-next-line max-len
    // functions like this, have to be memoized when using it inside of useEffect (eslint dependency array)
    setIsDiscountModalOpen(false);
  }, []);

  const [
    recreatePayment,
    { data: recreatePaymentData, isLoading: isRecreatePaymentLoading },
  ] = useRecreatePaymentMutation();

  const initialPaymentAmount = useMemo(
    () => +(search.get('amount') || 0),
    [search],
  );

  useEffect(() => {
    if (!paymentsData) {
      setPaymentsList([]);
      return;
    }
    setPaymentsList(
      paymentsData.map(
        ({ id, createdAt, payment, amount, invoice, type, details }) => ({
          id,
          amount,
          createdAt,
          invoice,
          type,
          details,
          key: id,
          paymentStatus: payment?.status,
          paymentId: payment?.transactionId,
        }),
      ),
    );
  }, [paymentsData]);

  useEffect(() => {
    if (initialPaymentAmount) setIsPaymentModalOpen(true);
  }, [initialPaymentAmount]);

  useEffect(() => {
    if (!recreatePaymentData) return;

    setIsRedirecting(true);
    window.location.href = recreatePaymentData.url;
  }, [recreatePaymentData]);

  const onClickDownload = async (chargeId: number) => {
    const row = paymentsData?.find(({ id }) => chargeId === id);
    if (!row?.invoice) return;

    const pdfBlob = await getInvoicePdf(row.invoice.id);
    if (!pdfBlob) return;

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(pdfBlob);
    link.download = `${intl.formatMessage({ defaultMessage: 'faktura' })}_${
      row.invoice.number
    }.pdf`;

    link.click();
  };

  const onClickRecreatePayment = (chargeId: number) => {
    const row = paymentsData?.find(({ id }) => chargeId === id);
    if (!row) return;

    recreatePayment({
      chargeId,
      amount: row.payment.amount,
      groupId: row.payment.groupId,
    });
  };

  const getInfoBox = (
    title: ReactElement,
    mainValue: number,
    secondValue: ReactElement,
    icon: ReactElement,
    isSuccess?: boolean,
  ) => {
    return (
      <InfoBox
        title={title}
        mainValue={
          isUserFetching ? (
            <Skeleton paragraph={false} title active />
          ) : (
            <FormattedNumber
              value={mainValue}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="PLN"
            />
          )
        }
        secondValue={isUserFetching ? <span /> : secondValue}
        icon={icon}
        isSuccess={isSuccess}
      />
    );
  };
  return (
    <div className="layout-content mb-24">
      <PaymentStatusModal />
      <PaymentModal
        isOpen={isPaymentModalOpen}
        initialAmount={initialPaymentAmount}
        onClose={() => setIsPaymentModalOpen(false)}
      />
      <DiscountCodeModal
        isOpen={isDiscountModalOpen}
        onClose={handleCloseDiscountCodeModal}
      />
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        <Col {...colProps}>
          <CtaButton onClick={() => setIsPaymentModalOpen(true)}>
            <FormattedMessage defaultMessage="Zasil konto" />
            <GiReceiveMoney size={25} />
          </CtaButton>
        </Col>
        <Col {...colProps}>
          <CtaButton onClick={() => setIsDiscountModalOpen(true)}>
            <FormattedMessage defaultMessage="Zrealizuj kod rabatowy" />
            <CiDiscount1 size={25} />
          </CtaButton>
        </Col>
        <Col {...colProps}>
          {getInfoBox(
            <FormattedMessage defaultMessage="Stan Twojego konta" />,
            userData?.account.mainValue || 0,
            <FormattedMessage
              defaultMessage="{signs} znaków"
              values={{
                signs: (
                  <FormattedNumber value={userData?.account.secondValue || 0} />
                ),
              }}
            />,
            <FaWallet />,
            !!userData?.account.secondValue,
          )}
        </Col>
        <Col {...colProps}>
          {getInfoBox(
            <FormattedMessage defaultMessage="Zaoszczędziłeś z nami około" />,
            userData?.saved.mainValue || 0,
            <a
              rel="noreferrer"
              href="https://businesswriter.ai/oszczedzaj-na-copywritingu/"
              target="_blank"
            >
              <FormattedMessage defaultMessage="Jak to liczymy?" />
            </a>,
            <BsFillPiggyBankFill />,
          )}
        </Col>
      </Row>

      <Table<PaymentRow>
        isLoading={
          isPaymentsFetching || isRecreatePaymentLoading || isRedirecting
        }
        cardHeader={
          <Title level={5}>
            <FormattedMessage defaultMessage="Twoje płatności" />
          </Title>
        }
        data={paymentsList}
        columns={columns(onClickDownload, onClickRecreatePayment)}
      />
    </div>
  );
};

export default Payments;
