import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal, Result } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Routes } from 'components/main/App/Routing';
import {
  useLazyGetPaymentQuery,
  useRecreatePaymentMutation,
} from 'ducks/payment/service';
import { PaymentStatus } from 'ducks/payment/types';
import {
  getPaymentStatus,
  mapPaymentStatusToLabel,
  mapPaymentStatusToResultStatus,
} from './utils';

const PaymentStatusModal: React.FC = () => {
  const params = useParams<'*'>();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [requestInterval, setRequestInterval] = useState<
    ReturnType<typeof setInterval> | undefined
  >();

  const [getPayment, { isFetching, data }] = useLazyGetPaymentQuery();
  const [
    recreatePayment,
    { data: recreatePaymentData, isLoading: isRecreatePaymentLoading },
  ] = useRecreatePaymentMutation();

  // get charge id from url
  const chargeId = useMemo(() => +(search.get('charge_id') || 0), [search]);
  const paymentStatus = getPaymentStatus(params, data, isFetching);

  const clearIntervalIfExists = () => {
    if (requestInterval) {
      clearInterval(requestInterval);
      setRequestInterval(undefined);
    }
  };

  // get charge if charge_id is in url
  useEffect(() => {
    if (chargeId) {
      getPayment(chargeId, false);
      setIsOpen(true);
    }
  }, [chargeId, getPayment]);

  // periodicaly ask backend for payment status if the current status is pending
  useEffect(() => {
    if (paymentStatus !== PaymentStatus.Pending || !data || !chargeId) {
      return clearIntervalIfExists();
    }

    const intervalId = setInterval(() => {
      getPayment(chargeId, false);
    }, 5000);

    setRequestInterval(intervalId);

    return clearIntervalIfExists;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chargeId, getPayment]);

  // redirect to payment
  useEffect(() => {
    if (!recreatePaymentData) return;

    setIsRedirecting(true);
    window.location.href = recreatePaymentData.url;
  }, [recreatePaymentData]);

  const onClose = () => {
    clearIntervalIfExists();
    setIsOpen(false);
    navigate(Routes.PAYMENTS);
  };

  const handleRecreatePayment = () => {
    if (!chargeId || !data) return;

    recreatePayment({
      chargeId,
      amount: data.payment.amount,
      groupId: data.payment.groupId,
    });
  };

  return (
    <Modal
      title={<FormattedMessage defaultMessage="Status Twojej płatności" />}
      open={isOpen}
      onCancel={onClose}
      footer={false}
    >
      <Result
        status={mapPaymentStatusToResultStatus(paymentStatus)}
        icon={
          paymentStatus === PaymentStatus.Pending ? (
            <LoadingOutlined />
          ) : undefined
        }
        title={mapPaymentStatusToLabel(paymentStatus)}
        extra={
          <>
            {paymentStatus !== PaymentStatus.Pending && (
              <Button type="primary" key="close" onClick={onClose}>
                <FormattedMessage defaultMessage="Wróć na listę płatności" />
              </Button>
            )}
            {paymentStatus === PaymentStatus.Error && (
              <Button
                type="primary"
                key="recreate"
                onClick={handleRecreatePayment}
                loading={isRecreatePaymentLoading || isRedirecting}
              >
                <FormattedMessage defaultMessage="Opłać ponownie" />
              </Button>
            )}
          </>
        }
      />
    </Modal>
  );
};

export default PaymentStatusModal;
