import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Button, Result } from 'antd';
import axios from 'axios';
import React, { useEffect, ReactElement, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Loader from 'components/ui/atoms/Loader';
import { useGetCurrentUserQuery } from 'ducks/user/service';
import TermsAndConditions from './components/TermsAndConditions';
import styles from './styles.module.scss';
import { getTermsAndConditionsParams } from './utils';

const setAccessToken = async (
  getAccessTokenSilently: (config: any) => Promise<string>,
) => {
  axios.interceptors.request.use(async (config) => {
    const requestConfig = config;
    const accessToken = await getAccessTokenSilently({});

    if (requestConfig.headers)
      requestConfig.headers.Authorization = `Bearer ${accessToken}`;

    return requestConfig;
  });
};

type Props = {
  children: ReactElement;
};

const termsAndConditionsParams = getTermsAndConditionsParams();

const Auth: React.FC<Props> = ({ children }) => {
  const intl = useIntl();
  const [accessTokenIsSet, setAccessTokenIsSet] = useState(false);
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading: isLoadingAuthData,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const {
    data: dbUser,
    isLoading: isLoadingDbData,
    isSuccess: isDbUserSuccess,
    isError: isDbUserError,
  } = useGetCurrentUserQuery(undefined, {
    skip: !accessTokenIsSet,
  });

  const isLoading = isLoadingAuthData || isLoadingDbData;

  useEffect(() => {
    if (accessTokenIsSet || termsAndConditionsParams) return;
    if (!isLoadingAuthData && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.href,
        },
      });
    }

    if (isAuthenticated) {
      setAccessToken(getAccessTokenSilently);
      setAccessTokenIsSet(true);
    }
  }, [
    isAuthenticated,
    isLoadingAuthData,
    accessTokenIsSet,
    loginWithRedirect,
    getAccessTokenSilently,
  ]);

  // T&C
  if (termsAndConditionsParams)
    return (
      <TermsAndConditions
        domain={termsAndConditionsParams.domain}
        state={termsAndConditionsParams.state}
      />
    );

  return (
    <Loader
      text={intl.formatMessage({ defaultMessage: 'Ładuję aplikację' })}
      visible={isLoading}
      isAbsolute={true}
    >
      <>
        {isAuthenticated && isDbUserSuccess && children}
        {isDbUserError && (
          <Result
            className={styles.errorWrapper}
            status="error"
            title={intl.formatMessage({
              defaultMessage: 'Nie udało się zalogować',
            })}
            subTitle={intl.formatMessage({
              defaultMessage:
                'Prawdopodobnie Twoje konto nie istnieje, wyloguj się i spróbuj ponownie. Jeżeli uważasz że jest to błąd, skontaktuj się z naszym działem BOK',
            })}
            extra={[
              <Button type="primary" onClick={() => logout()}>
                <FormattedMessage defaultMessage="Wyloguj się" />
              </Button>,
            ]}
          />
        )}
      </>
    </Loader>
  );
};

const AuthProvider: React.FC<Props> = ({ children }) => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    }}
  >
    <Auth>{children}</Auth>
  </Auth0Provider>
);

export default AuthProvider;
