import { useAuth0 } from '@auth0/auth0-react';
import { Button, ButtonProps } from 'antd';
import cn from 'classnames';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = Pick<ButtonProps, 'type' | 'icon'> & {
  children?: ReactElement | ReactElement[];
  className?: string;
};

const LogoutButton: React.FC<Props> = ({ children, type, icon, className }) => {
  const { isAuthenticated, logout } = useAuth0();

  if (!isAuthenticated) return null;

  return (
    <Button
      data-testid="logout"
      type={type || 'link'}
      icon={icon}
      onClick={() => logout()}
      className={cn({ 'text-muted': !type }, className)}
    >
      <span>
        {children || <FormattedMessage defaultMessage="Wyloguj się" />}
      </span>
    </Button>
  );
};

export default LogoutButton;
