import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from 'components/main/App';
import AuthProvider from 'components/main/AuthProvider';
import Intl from 'components/main/Intl';
import store from 'ducks/store';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

// Sentry.init({
//   dsn: 'https://edcf58ebcd5d1c4521b7a2aa29ab89cf@o280030.ingest.sentry.io/4505701833048064',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         'localhost',
//         'https://beta.businesswriter.ai/api/',
//         'https://panel.businesswriter.ai/api/',
//       ],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Intl>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Intl>
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
