import { Button, ButtonProps, Card, Checkbox } from 'antd';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { terms } from './config';
import styles from './styles.module.scss';

type Props = Pick<ButtonProps, 'type' | 'icon'> & {
  domain: string;
  state: string;
};

const TermsAndConditions: React.FC<Props> = ({ domain, state }) => {
  const form = useRef<HTMLFormElement>(null);

  return (
    <Card bordered={false} className={`criclebox ${styles.card}`}>
      <div className={styles.space}>
        <Logo className={styles.logo} />
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: terms }}
        />
        <form
          ref={form}
          action={`https://${domain}/continue?state=${state}`}
          method="post"
          className={styles.space}
        >
          <input type="hidden" name="confirm" value="yes" />
          <Checkbox defaultChecked={true} value="yes" name="newsletter">
            <FormattedMessage defaultMessage="Zgadzam się na otrzymywanie wiadomości marketingowych." />
          </Checkbox>
          <Button
            type="primary"
            onClick={() => form.current && form.current.submit()}
          >
            <FormattedMessage defaultMessage="Akceptuję warunki serwisu" />
          </Button>
        </form>
      </div>
    </Card>
  );
};

export default TermsAndConditions;
