import { Alert, Space } from 'antd';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { Routes } from 'components/main/App/Routing';
import { CalculateDocumentResponse } from 'ducks/document/types';
import PriceTooltip from './components/PriceTooltip';

type Props = {
  shouldRenderPrice: boolean;
  calculateIsLoading: boolean;
  copywriterPrice: number;
  calculateData: CalculateDocumentResponse | undefined;
  documentErrorData: any;
  balance: number | null;
};

const ResponseBoxes: React.FC<Props> = ({
  shouldRenderPrice,
  calculateIsLoading,
  copywriterPrice,
  calculateData,
  documentErrorData,
  balance,
}) => {
  const absBalance = balance !== null ? Math.abs(balance) : 0;
  return shouldRenderPrice ? (
    <Space direction="vertical" style={{ width: '100%' }}>
      <PriceTooltip
        isLoading={calculateIsLoading}
        copywriterPrice={copywriterPrice}
        data={calculateData}
        shouldRender={shouldRenderPrice}
      />
      {documentErrorData?.status === 422 ||
      (!!balance && balance < 0) ? (
        <Alert
          description={
            <FormattedMessage
              defaultMessage="Brak wystarczających środków. Brakuje ci {balance}. {link}"
              values={{
                balance: (
                  <FormattedNumber
                    value={absBalance}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency="PLN"
                  />
                ),
                link: (
                  <Link
                    to={`${Routes.PAYMENTS}?amount=${absBalance}`}
                    target="_blank"
                  >
                    <FormattedMessage defaultMessage="Zasil konto tutaj" />
                  </Link>
                ),
              }}
            />
          }
          type="error"
          showIcon
        />
      ) : null}
    </Space>
  ) : null;
};

export default ResponseBoxes;
