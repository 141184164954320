import { Form, Input, Modal } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/ui/atoms/Loader';
import {
  useCreateLicenseMutation,
  useUpdateLicenseMutation,
} from 'ducks/licenses/service';
import { LicenseRow } from 'views/License/config';
import { useEffect } from 'react';

const { Item } = Form;

type Props = {
  notify: NotificationInstance;
  isOpen: boolean;
  selectedLicense?: LicenseRow;
  onClose: () => void;
};

const LicenseModal: React.FC<Props> = ({
  notify,
  isOpen,
  onClose,
  selectedLicense,
}) => {
  const [form] = Form.useForm();
  const [
    updateLicense,
    {
      isLoading: isUpdating,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateLicenseMutation();
  const [
    createLicense,
    {
      isLoading: isCreating,
      isError: isCreateError,
      isSuccess: isCreateSuccess,
    },
  ] = useCreateLicenseMutation();

  const handleFormSubmit = () => {
    if (isUpdating || isCreating) return;

    form
      .validateFields()
      .then((values) =>
        typeof selectedLicense === 'undefined'
          ? createLicense(values)
          : updateLicense({ ...values, id: selectedLicense.id }),
      )
      .then(onClose);
  };

  useEffect(() => {
    if (isUpdating || isCreating) return;

    if (isUpdateError || isCreateError) {
      notify.error({
        message: (
          <FormattedMessage defaultMessage="Wystąpił błąd podczas zapisu licencji." />
        ),
      });
    }

    if (isUpdateSuccess || isCreateSuccess) {
      notify.success({
        message: (
          <FormattedMessage defaultMessage="Licencja została zapisana." />
        ),
      });
    }
  }, [
    isUpdating,
    isCreating,
    isUpdateError,
    isUpdateSuccess,
    isCreateError,
    isCreateSuccess,
    notify,
  ]);

  let title = <FormattedMessage defaultMessage="Dodaj licencję" />;
  let btnText = <FormattedMessage defaultMessage="Dodaj licencję" />;

  if (selectedLicense) {
    title = <FormattedMessage defaultMessage="Edytuj licencję" />;
    btnText = <FormattedMessage defaultMessage="Zapisz" />;
  }

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={onClose}
      onOk={handleFormSubmit}
      okText={btnText}
      cancelText={<FormattedMessage defaultMessage="Anuluj" />}
    >
      <Loader visible={isUpdating || isCreating}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ name: selectedLicense?.name }}
        >
          <Item
            label={<FormattedMessage defaultMessage="Nazwa" />}
            name="name"
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage defaultMessage="Podaj nazwę licencji" />
                ),
              },
            ]}
          >
            <Input />
          </Item>
        </Form>
      </Loader>
    </Modal>
  );
};

export default LicenseModal;
