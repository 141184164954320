import { Col, Skeleton } from 'antd';
import InfoBox from 'components/ui/molecules/InfoBox';
import { useGetCurrentUserStatisticsQuery } from 'ducks/user/service';
import { getInfoBoxData } from 'views/Dashboard/config';

const defaultCount = 3;

// commented out values are for 4 boxes
const colProps = {
  xs: 24, // 24
  sm: 24, // 24
  md: 24, // 12
  lg: 24, // 12
  xl: 8, // 12
  xxl: 8, // 6
  className: 'mb-24',
};

const InfoBoxes: React.FC = () => {
  const { data, isFetching } = useGetCurrentUserStatisticsQuery();

  if (!data || isFetching) {
    return (
      <>
        {Array.from({ length: defaultCount }, (_, idx) => (
          <Col key={`sk-${idx}`} {...colProps}>
            <InfoBox
              title={<Skeleton paragraph={false} title active />}
              mainValue={<Skeleton paragraph={false} title active />}
              secondValue={<span />}
              icon={<span />}
              iconLink=""
            />
          </Col>
        ))}
      </>
    );
  }

  return (
    <>
      {getInfoBoxData(data).map((props, idx) => (
        <Col
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          {...colProps}
        >
          <InfoBox {...props} />
        </Col>
      ))}
    </>
  );
};

export default InfoBoxes;
